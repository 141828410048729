import styled from 'styled-components'

const Styles = styled.div`
    text-align: center;
    padding-bottom: 15px;

    h2 {

        font-weight: 800;
        letter-spacing: -0.048em;
    }
    @media screen and (max-width: 768px) {
        h2 {
            font-size: 24px;
            font-weight: 700;
        }
    }

`
;
export const ExtraTime = () => {
    return (
        <Styles>
            <h2>What would you do with a couple of extra hours every month?</h2>
            <p className="text-muted">See what tasks you can do with a few clicks.</p>
        </Styles>
    )
};