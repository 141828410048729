import { Col, Accordion, Card, Button, Row, Form, ButtonGroup, Dropdown, DropdownButton } from 'react-bootstrap'
import stockhist2 from './assets/stockhist2.png'
import stockhistory1 from './assets/stockhistory1.png'
import { SideBar } from "./components/Sidebar"
import React, { useRef, useState } from "react";
import LoaderButton from "./components/LoaderButton";
import { onError } from "./libs/errorLib";
import { ordersAPI, s3Upload } from './libs/awsLib';
import { Storage } from 'aws-amplify';
import styled, { keyframes } from 'styled-components'
import { bounceIn } from 'react-animations'
import { Helmet } from 'react-helmet'
import stockUpload from './assets/stockUpload.gif'
import openStockOrder from './assets/openStockOrder.gif'


const swingAnimation = keyframes`${bounceIn}`;

const Styles = styled.div`
  .Form {
    margin: 0 auto;
    max-width: 320px;
  }
  .file-border {
    border: 1px solid #dee2e6;
    width: max-content;
  }
  .head {
    font-weight: 550;
    border-bottom: 1px solid #dee2e6; 
  }
  .s3link {
    font-size: 15px;
    animation: 1s ${swingAnimation};
    color: #007bff;
  }
`;

const MAX_ATTACHMENT_SIZE = 5000000;

export default function Orders() {
    const file = useRef(null);
    const [isLoading, setIsLoading] = useState(false);
    const [signedURL, setSignedUrl] = useState('');
    const [supplier, setSupplier] = useState('')

  function handleFileChange(event) {
    file.current = event.target.files[0];
    console.log(supplier);
  }

  async function handleSubmit(event) {
    event.preventDefault();

    if (file.current && file.current.size > MAX_ATTACHMENT_SIZE) {
      alert(
        `Please pick a file smaller than ${MAX_ATTACHMENT_SIZE /
          1000000} MB.`
      );
      return;
    }

    setIsLoading(true);

    try {
    const attachment = file.current ? await s3Upload(file.current) : null;
    console.log(attachment);
    const apiCallFile = file.current ? await ordersAPI(attachment, supplier) : null;
    console.log(apiCallFile);

    // get the signed URL string
    const theURL = await Storage.get(apiCallFile, {level: 'private', contentType: 'string', download: "true"}); // get key from Storage.list
    setSignedUrl(theURL);

    
    setIsLoading(false);
    } catch (e) {
      onError(e);
      setIsLoading(false);
    }
    
  }

    return (
    <Row className="pl-2 pt-3 pb-3">
      <Helmet>
                <title>Orders</title>
            </Helmet>
        <Col className="d-none d-sm-block sidebar-width pt-3 border border-grey rounded sidebar-height sticky-top" sm={2}> 
        <SideBar />
        </Col>
    <Col className="align-self-center">
    <Styles>
   <Accordion>
  <Card>
    <Card.Header>
      <Accordion.Toggle as={Button} variant="link" eventKey="0">
        How to use
      </Accordion.Toggle>
    </Card.Header>
    <Accordion.Collapse eventKey="0">
      <Card.Body>
        <Card.Title>
          Generating Your Stock Order
        </Card.Title>
        <div className="text-muted">
          <p>Navigate to your software vendors stock history function.</p>
          <p>Select the time period you want to base your order on.</p>
          <p><b>Important: </b>Select the parameters: <b>Product Name</b>, <b>Dispensed Quantity</b> and <b>GMS Code</b>.</p>
          <p>Below is a screenshot from the McLernon's dispensing software.</p>
          <Card.Img src={stockhistory1} variant="top" />
          <p> </p>
          <p>Generate the report.</p> 
          <p><b>Important: </b>Export to a <b>csv</b> file.</p>
          <Card.Img src={stockhist2} variant="top" />
          <p> </p>
          <p>Upload file to Swiftask.</p>
          <div className='text-center mb-3'>
            <img src={stockUpload}
            alt="gif"
            height="300"
            width="500" />
          </div>

          <p>Receive your PCO and Generic orders.</p>
          <div className='text-center mt-2'>
            <img src={openStockOrder}
            alt="gif"
            height="300"
            width="500" />
            </div>
        </div>
      </Card.Body>
    </Accordion.Collapse>
  </Card>
  </Accordion>
    <Form className="text-center pt-4 pb-3" onSubmit={handleSubmit}>
        <Form.Group className="pb-3" controlId="file">
          <Form.Label><h5>Click to upload stock report</h5></Form.Label>
          <Form.Control className="text-center" onChange={handleFileChange} type="file" />
        </Form.Group>
        <DropdownButton
          as={ButtonGroup}
          menuAlign={{ lg: 'right' }}
          title="Select Preferred Supplier"
          id="dropdown-menu-align-responsive-1"
          className='mb-3'
          >
          <Dropdown.Item onClick={() => setSupplier("")}>NONE</Dropdown.Item>
          <Dropdown.Item onClick={() => setSupplier("ACCORD HC")}>ACCORD HC</Dropdown.Item>
          <Dropdown.Item onClick={() => setSupplier("ACTAVIS")}>ACTAVIS</Dropdown.Item>
          <Dropdown.Item onClick={() => setSupplier("BLUEFISH")}>BLUEFISH</Dropdown.Item>
          <Dropdown.Item onClick={() => setSupplier("CLONMEL")}>CLONMEL</Dropdown.Item>
          <Dropdown.Item onClick={() => setSupplier("KRKA PHARM")}>KRKA PHARM</Dropdown.Item>
          <Dropdown.Item onClick={() => setSupplier("PINEWOOD")}>PINEWOOD</Dropdown.Item>
          <Dropdown.Item onClick={() => setSupplier("ROWA")}>ROWA</Dropdown.Item>
          <Dropdown.Item onClick={() => setSupplier("TEVA")}>TEVA</Dropdown.Item>
          
        </DropdownButton>
        <LoaderButton
          className="mb-3"
          type="submit"
          size="lg"
          variant="primary"
          isLoading={isLoading}
        >
          Upload
        </LoaderButton>
      </Form>     
        { signedURL ? 
          <Row className="pt-2 pb-4">
            <Col md={{ span: 6, offset: 2}}>
          <div className="file-border pt-1 pb-1">
            <div className="head text-center pt-1 pb-1 pl-2 pr-2">
            Click below to download order file
            </div>
            <div className="s3link text-center pt-1 pb-1 pl-2 pr-2">
              <a className="s3link" href={signedURL} target="_blank">Generic and PCO orders</a>
            </div>
        </div>
        </Col>
        </Row>
          : null }
        </Styles>
    </Col>
    </Row>
    )
};

