import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import styled from 'styled-components'

const Styles = styled.div`
    text-align: center;
    padding: 20px;

    h4 {
        padding-top: 8px;
        font-weight: 600;
        font-size: 19px
    }
    p {

    }
`
;



export const Icon = (props) => (
    <Styles>
        <FontAwesomeIcon color={props.color} icon={props.faIcon} size="5x"  />
        <h4>{props.header}</h4>
               <p className="text-muted">{props.paragraph}</p>
    </Styles>
    );
