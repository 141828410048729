// import the library
import { library } from '@fortawesome/fontawesome-svg-core';

// import your icons
import { faChartLine, faClipboardCheck, faLock, faEuroSign, faCog, faUserCog, faSignOutAlt, faTasks, faShippingFast , faQuestionCircle, faHome, faTimesCircle, faCloudDownloadAlt, faFileDownload, faSort} from '@fortawesome/free-solid-svg-icons';

library.add(
    faClipboardCheck,
    faChartLine,
    faLock,
    faEuroSign,
    faCog,
    faUserCog,
    faSignOutAlt,
    faTasks,
    faShippingFast,
    faQuestionCircle,
    faHome,
    faTimesCircle,
    faCloudDownloadAlt,
    faFileDownload,
    faSort
);
