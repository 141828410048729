import React from "react";
import styled from 'styled-components'
import { Auth } from 'aws-amplify'
import  { useState } from "react";
import Form from "react-bootstrap/Form";
import { useHistory } from "react-router-dom";
import LoaderButton from "./components/LoaderButton";
import { useAppContext } from "./libs/contextLib";
import { useFormFields } from "./libs/hooksLib";
import { onError } from "./libs/errorLib";
import { Helmet } from 'react-helmet'
import { Link } from "react-router-dom";
import { addContactToSendgrid } from "./libs/awsLib";

const Styles = styled.div`
    .heading {
            margin: 40px 0px 20px;
            text-align: center;
            font-family: "Open Sans", sans-serif;
            font-size: 42px;
            font-weight: 700;
            letter-spacing: -1px;
            line-height: 32px;
        }
    @media all and (min-width: 480px) {
    .Signup {
        padding: 60px 0;
        }

    .Signup form {
      margin: 0 auto;
      max-width: 320px;
        }
}
`;


export default function Register() {
  const [fields, handleFieldChange] = useFormFields({
    email: "",
    password: "",
    confirmPassword: "",
    confirmationCode: "",
  });
  const history = useHistory();
  const [newUser, setNewUser] = useState(null);
  const { userHasAuthenticated } = useAppContext();
  const [isLoading, setIsLoading] = useState(false);

  const [privacyChecked, setPrivacyChecked] = useState(false);
  const [emailChecked, setEmailChecked] = useState(false);

  function validateForm() {
    return (
      fields.email.length > 0 &&
      fields.password.length > 0 &&
      fields.password === fields.confirmPassword
    );
  }

  function validateConfirmationForm() {
    return fields.confirmationCode.length > 0;
  }

  const PrivacyAndEmail = () => {

  const handleEmailChange = (e) => {
    console.log('before', emailChecked)
    setEmailChecked(e.target.checked);
    console.log('after', emailChecked)
  }
  
  const handlePrivacyChange = (e) => {
    console.log('before', privacyChecked)
    setPrivacyChecked(e.target.checked);
    console.log('after', privacyChecked)
  }

  return (
    // <div>
    //   <input
    //     type="checkbox"
    //     id="emailCheckbox"
    //     checked={emailChecked}
    //     onChange={handleCheckboxChange}
    //   />
    //   <label for="emailCheckbox">Swiftask will occasionally send helpful and relevant emails.</label>
    // </div>
    <div>
    <small className="text-muted">
            <input 
              type="checkbox" 
              id="emailCheckbox" 
              checked={privacyChecked}
              onChange={handlePrivacyChange}
              />&nbsp;
            By signing up you agree to our 
          <Link to='/privacy'> Privacy Policy</Link> and <Link to='/terms'>Terms and Conditions</Link>
          </small>
          <small className="text-muted">
          
            <label for="emailCheckbox">
              <input 
                  type="checkbox" 
                  id="emailCheckbox2"
                  checked={emailChecked}
                  onChange={handleEmailChange} 
                  />&nbsp;
                By ticking this box Swiftask will occasionally send helpful and relevant emails including tutorials on how to use Swiftask services</label>
          </small>
      </div>
    );
  };

  // async function handleSubmit(event) {
  //   event.preventDefault();
  
  //   setIsLoading(true);
  
  //   try {
  //     // sign up if privacy is checked
  //     if (privacyChecked) { 
  //       alert('Thanks for signing up!')
  //     // const newUser = await Auth.signUp({
  //       // username: fields.email,
  //       // password: fields.password,
  //     // });
  //     } else {
  //       alert('Please agree to our Privacy Policy and Terms and Conditions');
  //     }

  //     if (emailChecked) {
  //       // add to sendgrid list
  //       alert('Added to email list')
  //     }
  //     setIsLoading(false);
  //     setNewUser(newUser);
  //   } catch (e) {
  //     onError(e);
  //     setIsLoading(false);
  //   }
  // }

  async function handleSubmit(event) {
  event.preventDefault();
  setIsLoading(true);

  try {
    if (!privacyChecked) {
      alert('Please agree to our Privacy Policy and Terms and Conditions');
      setIsLoading(false);
      return;
    }

    // sign up
    const newUser = await Auth.signUp({
      username: fields.email,
      password: fields.password,
    });


    if (emailChecked) {
      // add to sendgrid list
      const email = await addContactToSendgrid(fields.email);
    }

    setIsLoading(false);
    setNewUser(newUser);
  } catch (e) {
    console.log('error', e)
    onError(e);
    setIsLoading(false);
  }
}
 
  async function handleConfirmationSubmit(event) {
    event.preventDefault();
    // handle error: "User cannot be confirmed. Current status is CONFIRMED"
    setIsLoading(true);
  
    try {
      await Auth.confirmSignUp(fields.email, fields.confirmationCode);
      await Auth.signIn(fields.email, fields.password);
      console.log('user confirmed')
      userHasAuthenticated(true);
      history.push("/dashboard");
    } catch (e) {
      onError(e);
      setIsLoading(false);
    }
  }

  function renderConfirmationForm() {
    return (
      
      <Form className="pb-4" onSubmit={handleConfirmationSubmit}>
        <Form.Group controlId="confirmationCode" size="lg">
          <Form.Label>Confirmation Code</Form.Label>
          <Form.Control
            autoFocus
            type="tel"
            onChange={handleFieldChange}
            value={fields.confirmationCode}
          />
          <Form.Text muted>Please check your email for the code.</Form.Text>
        </Form.Group>
        <LoaderButton
          block
          size="lg"
          type="submit"
          variant="success"
          isLoading={isLoading}
          disabled={!validateConfirmationForm()}
        >
          Verify
        </LoaderButton>
      </Form>
    );
  }

  function renderForm() {
    return (
      <Form className="pb-4" onSubmit={handleSubmit}>
        <Form.Group controlId="email" size="lg">
          <Form.Label>Email</Form.Label>
          <Form.Control
            autoFocus
            type="email"
            value={fields.email}
            onChange={handleFieldChange}
          />
        </Form.Group>
        <Form.Group controlId="password" size="lg">
          <Form.Label>Password</Form.Label>
          <Form.Control
            type="password"
            value={fields.password}
            onChange={handleFieldChange}
          />
        </Form.Group>
        <Form.Group controlId="confirmPassword" size="lg">
          <Form.Label>Confirm Password</Form.Label>
          <Form.Control
            type="password"
            onChange={handleFieldChange}
            value={fields.confirmPassword}
          />
        </Form.Group>
        <LoaderButton
          block
          size="lg"
          type="submit"
          variant="success"
          isLoading={isLoading}
          disabled={!validateForm()}
        >
          Signup
        </LoaderButton>


        <PrivacyAndEmail/>

        
      </Form>
    );
  }

  return (
      <Styles>
        <Helmet>
                <title>Register</title>
            </Helmet>
          <h2 className="heading">Register</h2>
    <div className="Signup">
      {newUser === null ? renderForm() : renderConfirmationForm()}
    </div>
    </Styles>
  );
}



{/* <small className="text-muted">
          <input type="checkbox" id="emailCheckbox" />&nbsp;
          By signing up you agree to our 
        <Link to='/privacy'> Privacy Policy</Link> and <Link to='/terms'>Terms and Conditions</Link>
        </small>
        <small className="text-muted">
          
            <label for="emailCheckbox">
              <input type="checkbox" id="emailCheckbox2" />&nbsp;
              By ticking this box Swiftask will occasionally send helpful and relevant emails including tutorials on how to use Swiftask services</label>
        </small> */}