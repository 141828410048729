import { Modal, Button } from 'react-bootstrap'
import { Link } from 'react-router-dom'

export const MyVerticallyCenteredModal = (props) => {
	return (
	  <Modal
	    {...props}
	    size="md"
	    aria-labelledby="contained-modal-title-vcenter"
	    centered
	  >
	    <Modal.Header closeButton>
	      <Modal.Title id="contained-modal-title-vcenter">
		Getting Started
	      </Modal.Title>
	    </Modal.Header>
	    <Modal.Body className='text-center'>
	      <h6>No Claim Data Detected</h6>
	      <div style={{fontSize: 14}}>
		To get Started using Swiftask, click on the 
		<Link to="/guide"> Guide</Link>.
		<br></br>Then go to
		<Link to="/orders"> Orders </Link>
		or
		<Link to="/claims"> Claims </Link>
		on the Dashboard.
	      </div>
	    </Modal.Body>
	    <Modal.Footer>
	      <Button onClick={props.onHide}>Close</Button>
	    </Modal.Footer>
	  </Modal>
	);
}