import  { useState } from "react";
import { Storage } from "aws-amplify";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import moment from 'moment'
import LoaderButton from "../LoaderButton";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCloudDownloadAlt, faFileDownload } from "@fortawesome/free-solid-svg-icons";
import styled, { keyframes } from 'styled-components'
import { bounceIn } from 'react-animations';
import { onError } from "../../libs/errorLib";
import { Card, Col, Row } from 'react-bootstrap'
import { getVatFiles } from "../../libs/awsLib";

const swingAnimation = keyframes`${bounceIn}`;

const Styles = styled.div`
  .fa-cloud-download-alt {
    margin-right: .5rem;
  }
  .file-border {
    border: 1px solid #dee2e6;
    width: max-content;
    border-radius: 5px;
  }
  .head {
    font-weight: 550;
    border-bottom: 1px solid #dee2e6; 
  }
  .s3link {
    font-size: 15px;
    animation: 1s ${swingAnimation};
    color: #007bff;
  }
  .fa-file-download {
    margin-right: .5rem;
  }
`;

const VatDateRangePicker = () => {
  const [dateRange, setDateRange] = useState([null, null]);
  const [startDate, endDate] = dateRange
  const [isLoading, setIsLoading] = useState(false);
  const [signedURL, setSignedUrl] = useState('');

  const handleClick =  async () => {
    
    setIsLoading(true)

    try {

    const from = moment(startDate).format('YYYYMM');
    const to = moment(endDate).format('YYYYMM')
    
    console.log('DateRange: ', from, to)

    const vatFile = await getVatFiles(from, to);
    console.log('vatFileLambdaResponse: ', typeof(vatFile)); 

    if (vatFile === false) {
      console.log('No vat file found');
      setIsLoading(false)
      setSignedUrl("No VAT files found for date range")

    } else {
      console.log('VAT files detected')

      const theURL = await Storage.get("vat_reports.zip", {level: 'private', contentType: 'string', download: "true"});
      console.log("TheURL: ", theURL);
      setSignedUrl(theURL);

      setIsLoading(false)
    }
    
  } catch(e) {
      
    onError(e)
    setIsLoading(false)
    
    }
  }
  return (
    <Styles>

      <Card className="text-center">
        <Card.Body>
        <Card.Title>VAT Report</Card.Title>
        <hr></hr>
        <Card.Text className="text-muted " style={{fontSize: 'small'}}>
          Select a date range to download VAT report.
          </Card.Text>
    
    <DatePicker
      selectsRange={true}
      startDate={startDate}
      endDate={endDate}
      onChange={(update) => {
        setDateRange(update);
      }}
    />
    <Card.Text style={{fontSize: 'small'}} className="pt-2 text-muted">
      
      { startDate && endDate && (
        <>
      <div>
        Get VAT and Summary Pages from
      </div> 
      <div className="mb-1">{moment(startDate).format("MM-YYYY")} to{" "}
        {moment(endDate).format("MM-YYYY")}.
        </div>
        </>)
  }
      
      <LoaderButton
          className="mb-3 mt-1 justify-content-right"
          type="submit"
          size="sm"
          variant="info"
          disabled={!startDate && !endDate}
          isLoading={isLoading}
          onClick={handleClick}
        >
          <FontAwesomeIcon icon={faCloudDownloadAlt} size="1x"/>
          Get VAT Report
        </LoaderButton>
         {/* todo - add download button */}
         { signedURL && (signedURL !== "No VAT files found for date range") ? 
               <a className="file-border s3link pt-1 pb-1 pl-1 pr-1" href={signedURL} target="_blank">
            <FontAwesomeIcon className="" icon={faFileDownload} size="1x"/> 
                 VAT Report</a>
         :
         signedURL === "No VAT files found for date range" ?
         <div>No VAT files found for date range</div>
         :
         null
          }
    </Card.Text>
    </Card.Body>
    </Card>
    </Styles>
  );
};
export default VatDateRangePicker;