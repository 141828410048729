import { react } from 'react'
import { Card, Badge } from 'react-bootstrap'
import { PieChart } from 'react-minimal-pie-chart'

export const ClaimSummary = (props) => (
	 <Card className='text-center h-100'>
          <Card.Body>
            <Card.Title style={{fontSize: "large"}} >Claims Summary</Card.Title>
              <hr></hr>
                <Card.Text style={{fontSize: 'medium'}}>
                <div>
                  <Badge style={{backgroundColor: '#28a745', color: "white", fontWeight: 600}}>{props.paid} Paid</Badge>
                </div>
                <div>
                  <Badge animated style={{backgroundColor: '#dc3545', color: "white", fontWeight: 600}} >{props.unpaid} Unpaid</Badge>
                </div>
                <PieChart
                  radius={30}
                  data={[
                      { title: 'Paid', value: props.paid , color: 'rgb(40, 167, 69)' },
                      { title: 'Unpaid', value: props.unpaid, color: 'rgb(220, 53, 69)' },
                        ]}
                  /> 
                </Card.Text>
          </Card.Body>
        </Card>
)
