import styled from 'styled-components'
import { Col, CardDeck, Row, Alert,  Badge, ListGroup, Card } from 'react-bootstrap' 
import { SideBar } from './components/Sidebar';
import { dashboardFigures, stockPercentages } from './libs/awsLib';
import { onError } from './libs/errorLib';
import { useEffect, useState } from 'react';
import { CheckboxContainer }  from './checkboxcontainer'
import { AppContext } from './libs/contextLib';
import { ClaimSummary } from './components/dashboardComponents/claimSummary'
import { CommonErrors } from './components/dashboardComponents/commonErrors';
import { DashboardLoading, TableLoading, StockDataLoading } from './components/dashboardComponents/loading';
import { MyVerticallyCenteredModal } from './components/dashboardComponents/modal';
import { NoStockData } from './components/dashboardComponents/noStockData';
import { StockData } from './components/dashboardComponents/stockData';
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import { QueryForm } from './components/dashboardComponents/queryForm';
import "feeder-react-feedback/dist/feeder-react-feedback.css"

const Styles = styled.div`
    // margin: 20px;
    .display-text {
        font-weight: 700;
    // .col-sm-2 {
    //   padding-top: 25px;
    // }
    .icon,
    .text {
      vertical-align: middle !important;
      display: inline-block;
      color: black !important;
    }
    a, .navbar-nav .nav-link {
      font-family: "Open Sans",sans-serif;
      color: rgb(33, 37, 41);
      &:hover {
        color: #007bff;
        text-decoration: none;
    }
  }
`
;

export const Dashboard = () => {

  const [dbClaimData, setDbClaimData] = useState(0);
  const [mostCommonErrorList, setMostCommonErrorList] = useState(0);
  const [paid, setPaid] = useState(0);
  const [unpaid, setUnpaid] = useState(0);
  const [modalShow, setModalShow] = useState(true);
  const [stockPercentagesData, setStockPercentagesData] = useState('')
	const [reasonsForError, setReasonsForError] = useState({})

  useEffect(() => {
    fetchData();
    }, [])

  const fetchData = async () => {

    try {
    let response = await (
      await dashboardFigures()
    );
    console.log(response); 
    let resp = await mostCommonErrors(response.Items);
    
    setDbClaimData(response.Items);
    setMostCommonErrorList(resp);

    let paidCLaims = await numPaid(response.Items);
    setPaid(paidCLaims);

    let unpaidClaims = await numUnpaid(response.Items);
    setUnpaid(unpaidClaims);

    // Getting Stock Values
    const stockResponse = await stockPercentages();
    setStockPercentagesData(stockResponse);

  } catch (error) {
    console.log(error);
    onError(error);
  }}

  function concatItems (item) {
      if (item.length === 1) {
        return item;
      } else {
        return item.join(",");
      }
    }

  function concatClaimSequence (claim, seq) {
    return claim + "_" + seq
  }

  function dateFix (date) {
      const newDate = date.replaceAll("/", "-");
      return newDate;
    };

  async function numPaid (data) {
      const count = data.filter(function(item){
      if (item.status === 'paid') {
        return true;
      } else {
        return false;
      }
    }).length;
      return count;
    }
  
    
  async function numUnpaid (data) {
      const count = data.filter(function(item){
      if (item.status === 'unpaid') {
        return true;
      } else {
        return false;
      }
    }).length;
      return count;
  }


  async function mostCommonErrors (data) {
    
    const counts = {};

    if (data === undefined) {
      return []
    }

    for (const obj of data) {
      counts[obj.error] = counts[obj.error]? counts[obj.error] + 1 : 1;
      }
    const mostCommonErrors = [];
    for (var error in counts) {
  	  mostCommonErrors.push([error, counts[error]]);
      } 
    mostCommonErrors.sort(function (a,b) {
    	return b[1] - a[1];
    });
    console.log(mostCommonErrors);
    return mostCommonErrors
  }
  
  const statusStyle = {
      'paid': {
        backgroundColor: 'rgba(0,201,167,.1)',
        color: '#00c9a7'
      },
      'unpaid': {
        backgroundColor: 'rgba(222,68,55,.1)',
        color: '#de4437'
      }
    };

  const errorsHTML = () => {
    if (mostCommonErrorList.length > 0) {
      const topFive = mostCommonErrorList.slice(0,5);

      // const colour = ["warning", "info", "danger", "dark", "secondary"]
      // const randomElement = () => colour[Math.floor(Math.random() * colour.length)];

      return (
      <>, 
        {topFive.map((item) => (
          <ListGroup.Item>{item[0]} <Badge variant="warning">{item[1]}</Badge></ListGroup.Item>
        ))}
      </>
      )
    } else {
      return (
      <>
      <ListGroup.Item>No rejected claims to show</ListGroup.Item>
      <Card.Text style={{fontSize: "12px"}} className='mb-2 mt-2'>
				    Upload <Link to='/claims'>Claim listings</Link> to analyse Claim errors
			    </Card.Text>
      </>
      )
    }

};

  return (
        <Styles>
          <Helmet>
                <title>Dashboard</title>
            </Helmet>
        <Row className="pl-2 pt-3 pb-3">
            <Col className="d-none d-sm-block sidebar-width pt-3 border border-grey rounded sidebar-height sticky-top" sm={2}>
            <SideBar />
            </Col>
            <Col>
  
  <Alert className='text-center' variant={'primary'}>
    Welcome to Swiftask dashboard!
  </Alert>

  <Row className="justify-content-md-center mb-3">

    { mostCommonErrorList ?
    <CardDeck className='mb-3'>

      <Col sm={4}>
       <ClaimSummary paid={paid} unpaid={unpaid} />
      </Col>
      <Col sm={7}>
        <CommonErrors errorHTML={errorsHTML} />
      </Col>
</CardDeck> :

/* while loading */
<DashboardLoading />
}

  </Row>

        { dbClaimData ? dbClaimData.length > 0 ?
        // trying to add reasons for error into component
        <AppContext.Provider value={{ fetchData, dbClaimData, reasonsForError, setReasonsForError}}>
          <CheckboxContainer statusStyle={statusStyle} concatitems={concatItems} concatClaimSequence={concatClaimSequence} rerenderDB={fetchData} datefix={dateFix} dbdata={dbClaimData} />
          <QueryForm />
          </AppContext.Provider>
        :
          <MyVerticallyCenteredModal show={modalShow} onHide={() => setModalShow(false)} />
          :

        // while loading
        
        <TableLoading />
}
{ stockPercentagesData !== "" ? stockPercentagesData !== "noDB" ?
<div>
<StockData stockPercentagesData={stockPercentagesData} /> 
          </div>
      :
      <NoStockData />
      :
      <StockDataLoading />  
}
      </Col>
    </Row> 
     <Alert className='text-center' variant={'warning'}>
  <a href='https://docs.google.com/forms/d/e/1FAIpQLSdMS7heKet8-YgTACkR_lyPMFaF8ndMsyvtJAkd-qywohQiqQ/viewform?usp=sf_link'>
    Please click here to fill in a feedback form about your experience with Swiftask.
  </a>
  </Alert>
  </Styles>
    );
  };
    