import styled from 'styled-components'
import { Card, CardDeck } from 'react-bootstrap'
import order from '../assets/order.jpg'
import business from '../assets/business.jpg'
import dataanalysis from '../assets/dataanalysis.jpg'
import React from 'react'
import tracking from "../assets/tracking.jpg"

const imgHeight = {
    maxHeight: '363px',
};

const Styles = styled.div`
    .card-deck {
        margin-bottom: 15px;
    }
    .card-title {
        letter-spacing: -1px;
        font-weight: 800;
    }
    .card {
    box-shadow: 0 0 10px 0 rgba(100, 100, 100, 0.26);
    }
`
;

export const InfoCards = () => {
    return (
        <Styles>
        <CardDeck>
  <Card>
    <Card.Img variant="top" src={order} />
    <Card.Body>
      <Card.Title>Stock Ordering</Card.Title>
      <Card.Text className="text-muted">
          Have your monthly orders generated in a few seconds rather than checking each individual item's stock history.
      </Card.Text>
    </Card.Body>
  </Card>
  <Card>
    <Card.Img variant="top" src={dataanalysis} />
    <Card.Body>
      <Card.Title>Track Rejects and Reclaims</Card.Title>
      <Card.Text className="text-muted">
          Stay up to date with all rejects and reclaim payments. Save yourself 
          the frustration of scanning hundreds of pages across multiple months to see if you have been paid.
      </Card.Text>
    </Card.Body>
  </Card>
  </CardDeck>
        <CardDeck>
  <Card>
    <Card.Img variant="top" src={business}/>
    <Card.Body>
      <Card.Title>Hardship Claim Tracker</Card.Title>
      <Card.Text className="text-muted">
          Exasperated about your hardship claim appearing in your claim file anywhere 
          from one to 6 months after posting? Worry no more! Get instant access to all payments.
      </Card.Text>
    </Card.Body>
  </Card>
  <Card>
<Card.Img style={imgHeight} variant="top" src={tracking} />
    <Card.Body>
      <Card.Title>Claim Analysis</Card.Title>
      <Card.Text className="text-muted">
      Get a snapshot of most common errors in your claim file to prevent them in the future.
      </Card.Text>
    </Card.Body>
  </Card>
  </CardDeck>
  </Styles>
    )
}