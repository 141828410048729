import { Link } from 'react-router-dom'
import styled from 'styled-components'
import { Helmet } from 'react-helmet'
import { Container } from 'react-bootstrap';

const Styles = styled.div`
    .heading {
        margin: 40px 0px 20px;
        text-align: center;
        font-family: "Open Sans", sans-serif;
        font-size: 42px;
        font-weight: 700;
        letter-spacing: -1px;
        line-height: 32px;
    }
`
;


const Privacy = () => 
    (
      <div>
        <Helmet>
          <title>Privacy Policy</title>
        </Helmet>
        <Styles>
          <Container>
   <h2 className='heading'>Privacy Policy</h2> 
<p>This privacy policy will explain how Swiftask uses the personal data we collect from you when you
 use our website.</p>

<h2>Topics</h2>
<ul>
<li>Who are we?</li>
<li>What data do we collect?</li>
<li>How do we collect your data?</li>
<li>How will we use your data?</li>
<li>How do we store your data?</li>
<li>Marketing</li>
<li>What are your data protection rights?</li>
<li>What are cookies?</li> 
<li>How do we use cookies?</li>
<li>What type of cookies do we use</li>
<li>How to manage your cookes?</li>
<li>Privacy policies of other websites</li>
<li>Changes to our privacy policy</li>
<li>How to contact us</li>
<li>How to contact the appropriate authorities</li>
</ul>
<h2>Who are we?</h2>

<p>Swiftask provides business services for pharmacists and pharmacies. The Swiftask website, (<a href='https://www.swiftask.ie'>https://www.swiftask.ie</a>) provides information on the services offered.</p>

<h2>What data do we collect?</h2>

<p>Swiftask collects the following data:</p>

<ul>
<li>User email address.</li>
<li>General web browsing will give us certain information such as IP or server address, the type of browser being used, the time and date our site was accessed and the address linking to our site.</li>
<li>If uploading stock order file:</li> 
  <ul>
    <li> Dispensed products and volumes (anonymised)</li>
  </ul>
<li>if uploading claim files:</li>
        <ul>
    <li>VAT and Summary pages of PCRS claim files (no patient data is stored)</li>
</ul>
</ul>
<h2>How do we collect your data?</h2>

<p>You directly provide Swiftask with most of the data we collect. We collect data and process data when you:</p>
<ul>
<li>Register online or place an order for any of our products or services.</li>
<li>Voluntarily complete a customer survey or provide feedback via email.</li>
<li>Use or view our website via your browser’s cookies.</li>
<li>Voluntarily submit a message on our Contact webpage.</li>
</ul>

<h2>How will we use your data?</h2>

<p>Our Company collects your data so that we can:</p>
<ul>
<li>Register you as a user.</li>
<li>Provide you with our services through Amazon Web Services (<a href="https://aws.amazon.com/compliance/gdpr-center">https://aws.amazon.com/compliance/gdpr-center/</a>)</li>
<li>Email you with special offers on other products and services we think you might like.</li>
<li>Respond to any queries or issues you may have.</li>
<li>Notify you about any changes to our service.</li>
<li>To administer our website including troubleshooting, data analysis, testing, research, statistical and survey purposes and to ensure that content from our site is presented in the most effective manner for you and for your computer.</li>
</ul>
<p>When Swiftask processes your order, it may send your data to, and also use the resulting information from, credit reference agencies to prevent fraudulent purchases.</p>

<h2>How do we store your data?</h2>

<p>We will not retain personal data for longer than is necessary for the purposes for which it was collected and in order to meet the legal and business requirements of managing your account.</p>

<p>Swiftask securely stores your data in servers located in Ireland provided by Amazon Web Services. (  

<a href='https://aws.amazon.com/compliance/data-privacy-faq/?nc=sn&loc=4'>https://aws.amazon.com/compliance/data-privacy-faq/?nc=sn&loc=4</a>
)</p>
<ul>
<li>We will retain personal data that is necessary for us to provide you with a service for a period of 5 years.</li>
<li>We will retain records of any financial transactions you enter into with us for a period of 7 years following the end of the year of said transaction.</li>
<li>We will retain other personal data as necessary for as long as required in order for us to meet our legal and regulatory requirements.</li>
<li>We will retain documentation relevant to services provided to customers for a period of 5 years.</li>
<li>VAT and Summary Pages stored securely in cloud with AWS Simple Storage (<a href="https://aws.amazon.com/s3/security/">https://aws.amazon.com/s3/security/</a>) for a period of 2 years and then automatically deleted</li>
<li>All files generated during Swiftask services will be automatically deleted every 24 hours</li>
</ul>
<h2>Marketing</h2>

<p>Swiftask would like to send you information about products and services of ours that we
 think you might like. Swiftask does not deal with any third parties.</p>

<p>If you no longer wish to be contacted for marketing purposes, please email info@swiftask.ie.</p>

<h2>What are your data protection rights?</h2>

<p>Swiftask would like to make sure you are fully aware of all of your data protection rights. Every user is entitled to the following:</p>

<p><b>The right to access</b> – You have the right to request Swiftask for copies of your personal data. We may charge you a small fee for this service.</p>
<p>
<b>The right to rectification</b>
 – You have the right to request that Swiftask correct any information you believe is inaccurate. You also have the right to request Swiftask to complete the information you believe is 
incomplete.
</p>

<p><b>The right to erasure</b> – You have the right to request that Swiftask erase your personal data, under certain conditions.</p>

<p><b>The right to restrict processing</b> – You have the right to request that Swiftask restrict the processing of your personal data, under certain conditions.</p>

<p><b>The right to object to processing</b> – You have the right to object to Swiftask’s processing of your personal data, under certain conditions.</p>

<p>
<b>The right to data portability</b>
 – You have the right to request that Swiftask transfer the data that
 we have collected to another organization, or directly to you, under 
certain conditions.
</p>

<p>If you make a request, we have one month to respond to you. If you would like to exercise any of these rights, 
please contact us at our email: info@swiftask.ie</p>

<h2>Cookies</h2>

<p>Cookies are text files placed on your computer to collect standard Internet log
information and visitor behavior information. When you visit our websites, we may collect information from you automatically through cookies or similar technology.</p>

<p>For further information, visit allaboutcookies.org.</p>

<h2>What types of cookies do we use?</h2>

<p>Swiftask does not currently use cookies.</p>

<h2>Privacy policies of other websites</h2>

<p>The Swiftask website contains links to other websites. Our privacy policy applies only to our website, so if you click on a link to another website, you should read their privacy policy.</p>

<h2>Changes to our privacy policy</h2>

<p>Swiftask keeps its privacy policy under regular review and places any updates on this web page. This privacy policy was last updated on 2 July 2022.</p>
 
<h2>How to contact us</h2>

<p>
If you have any questions about Swiftask’s privacy policy, the data we hold on you, or you 
would like to exercise one of your data protection rights, please do not hesitate to contact us.
</p>
<p>Email us at: info@swiftask.ie</p>

</Container>
      </Styles>
      </div>      
      
    );

export default Privacy;