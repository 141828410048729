import { API, Auth, formRow, Storage } from "aws-amplify";

export async function s3Upload(file) {
  const filename = `${Date.now()}-${file.name}`;
  // need to change scope to allow list with s3 keys in array

  const stored = await Storage.vault.put(filename, file, {
    contentType: file.type,
  });
  return stored.key;
}

export async function s3ClaimUpload(file) {
  /* Need to bring in array, s3Files into scope to append
  list of filenames which we pass into to scope to append
  filenames to pass into API */
  const filename = `${Date.now()}-${file.name}`;

  const stored = await Storage.vault.put(filename, file, {
    contentType: file.type,
  });
  return stored.key;
};

export async function s3MultiFileUpload(files) {
  let s3Files = [];
  for (let i = 0; i < files.length; i++) {
    console.log("i: ", i)
    console.log("files[i]", files[i]);
    let s3File = await s3ClaimUpload(files[i], s3Files);
    s3Files.push(s3File);
  }
  return s3Files;
};

export async function ordersAPI(filename, supplier) {
  const user = await Auth.currentAuthenticatedUser();
  const credentials = await Auth.currentUserCredentials();
  // console.log("UserID: ", credentials.identityId);
  const token = user.signInUserSession.idToken.jwtToken

  const requestInfo = {
    headers: {
      Authorization: token
    },
    queryStringParameters: { 
      name: `private/${credentials.identityId}/${filename}`,
      supplier: supplier
  }
  }
  const data = await API.get('stockapi', '/orders/fetch', requestInfo)
  return data;
}


export async function claimsAPI(fileList) {
  const user = await Auth.currentAuthenticatedUser();
  const credentials = await Auth.currentUserCredentials();
  const subID = await Auth.currentAuthenticatedUser(); 
  const token = user.signInUserSession.idToken.jwtToken

  const requestInfo = {
    headers: {
      Authorization: token
    },
    queryStringParameters: { 
      name: `private/${credentials.identityId}/${fileList}`,
      dynID: `eu-west-1-${subID.username}`
  }
  }
  const data = await API.get('claimsapi', '/claims/fetch', requestInfo)
  return data;
}

export async function dashboardFigures() {
  const user = await Auth.currentAuthenticatedUser();
  const subID = await Auth.currentAuthenticatedUser(); 
  const token = user.signInUserSession.idToken.jwtToken

  const requestInfo = {
    headers: {
      Authorization: token
    },
    queryStringParameters: { 
      dynID: `eu-west-1-${subID.username}`
  }
  }
  const data = await API.get('paidclaimsapi', '/claims/query', requestInfo)
  return data;
};


export async function removeClaims(claims) {
  const user = await Auth.currentAuthenticatedUser();
  const subID = await Auth.currentAuthenticatedUser(); 
  const token = user.signInUserSession.idToken.jwtToken

  const requestInfo = {
    headers: {
      Authorization: token
    },
    queryStringParameters: { 
      dynID: `eu-west-1-${subID.username}`,
      claimData: claims
  }
  }
  const data = await API.get('removeclaimsapi', '/claims/remove', requestInfo)
  console.log("Api sent:",  data );
  return data;
};


export async function sendEmail(body) {

  const requestInfo = {
    queryStringParameters: {
      key: body
    }
  }
  console.log("Body: ", body);
  const data = await API.get('sendemailapi', '/contact/send', requestInfo)
  return data;
};


export async function stockPercentages() {
  const user = await Auth.currentAuthenticatedUser();
  const subID = await Auth.currentAuthenticatedUser(); 
  const credentials = await Auth.currentUserCredentials();
  console.log(credentials.identityId);
  const token = user.signInUserSession.idToken.jwtToken

  const requestInfo = {
    headers: {
      Authorization: token
    },
    queryStringParameters: { 
      dynID: `${credentials.identityId}`
  }
  }
  console.log(requestInfo)
  const data = await API.get('stockpercentagesapi', '/dispensedata', requestInfo)
  console.log("Api sent:",  data );
  return data;
};

export async function sendUserDB(reasonForError) {
  const user = await Auth.currentAuthenticatedUser();
  const subID = await Auth.currentAuthenticatedUser(); 
  const token = user.signInUserSession.idToken.jwtToken
  const credentials = await Auth.currentUserCredentials();
  const userStorage = credentials.identityId;

  const requestInfo = {
    headers: {
      Authorization: token
    },
    queryStringParameters: { 
      dynID: `eu-west-1-${subID.username}`,
      userStorage: userStorage,
      reasonsForError: reasonForError
  }
  }
  console.log(requestInfo)
  const data = await API.get('queryformapi', '/queryform', requestInfo)
  console.log("Api sent:",  { data } );
  return data;
};


export async function getVatFiles(from, to) {
  const user = await Auth.currentAuthenticatedUser();
  const token = user.signInUserSession.idToken.jwtToken
  const credentials = await Auth.currentUserCredentials();

  const requestInfo = {
    headers: {
      Authorization: token
    },
    queryStringParameters: { 
      user: credentials.identityId,
      startDate: from,
      endDate: to 
  }
  }
  console.log(requestInfo)
  const data = await API.get('vatfilesapi', '/vat', requestInfo)
  console.log("Api sent:",  { data } );
  return data;
};

export async function pollTable() {
  const subID = await Auth.currentAuthenticatedUser(); 
  const user = await Auth.currentAuthenticatedUser();
  const token = user.signInUserSession.idToken.jwtToken

  const requestInfo = {
    headers: {
      Authorization: token
    },
    queryStringParameters: {
       dynID: `eu-west-1-${subID.username}`
    }
  }
  const data = await API.get('pollingtableapi', '/taskstatus', requestInfo)
  console.log("data from pollTable(): ", data)
  return data;
};

export async function addContactToSendgrid(email) {
  
  console.log("email: ", email)
  const requestInfo = {
    queryStringParameters: {
       email: email
    }
  }
  const data = await API.get('sendgridapi', '/add-contact', requestInfo)
  return data;
}