import React from 'react';
import { Link, useHistory } from 'react-router-dom';
import { Button, DropdownButton, Nav, Navbar, Container } from 'react-bootstrap';
import styled from 'styled-components';
import { Auth } from 'aws-amplify';
import { useAppContext } from '../libs/contextLib'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUserCog, faCog, faSignOutAlt, faHome} from '@fortawesome/free-solid-svg-icons';
import {faShippingFast, faTasks, faQuestionCircle } from '@fortawesome/free-solid-svg-icons';


const Styles = styled.div`
  .navbar {
    background-color: white;
    border-bottom: 1px solid rgb(230, 230, 230);
    -webkit-box-shadow: 0 4px 6px -6px #222;
    -moz-box-shadow: 0 4px 6px -6px #222;
    box-shadow: 0 4px 6px -6px #222;
  }
  a, .navbar-nav .nav-link {
    font-family: "Open Sans",sans-serif;
    color: rgb(33, 37, 41);
    &:hover {
      color: #007bff;
      text-decoration: none;
    }
  }
  .navbar-brand {
    // color:  rgb(124, 58, 237);
    color: #007bff ;
    &:hover {
      // color: rgb(124, 58, 237);
      color: #007bff;
      text-decoration: none;
    }
  .btn-props {
    box-shadow: 0 8px 16px 0 rgba(0,0,0,0.2), 0 6px 20px 0 rgba(0,0,0,0.19);
  }
  // .pl-2 {
    // padding-top: 0.8rem;
  }
  icon,
  .text {
    vertical-align: middle !important;
    display: inline-block;
  }
  svg:not(:root).svg-inline--fa {
    vertical-align: middle;
  }
  .btn:hover {
    color: #007bff;
  }
`;

const colr = {
  color: "white"
};

export default function NavigationBar() {
    const { isAuthenticated, userHasAuthenticated } = useAppContext();
    const history = useHistory();

    async function handleLogout() {
      // this helps clear the session from the browsers local storage
      await Auth.signOut();
      userHasAuthenticated(false);
      history.push("/");
    }

   if (isAuthenticated) { 
     return (
  <Styles>
    <Navbar expand="lg">
      <Container>
      <Navbar.Brand className="pr-5" href="/">Swiftask</Navbar.Brand>
      <Navbar.Toggle aria-controls="basic-navbar-nav" />
      <Navbar.Collapse id="basic-navbar-nav">
           <Nav className="ml-auto d-flex">
          {/* <Nav.Item className="d-none d-sm-block pl-2 pr-4">
            <Button style = { colr } as={Link} to="/dashboard" class="btn-props" variant="primary">Dashboard
              </Button>
            </Nav.Item> */}
            <Nav.Item className="d-none d-sm-block pl-4">
              <FontAwesomeIcon icon={faUserCog} size="2x" />
            </Nav.Item>
            <Nav.Item className="d-none d-sm-block">
              <DropdownButton variant="white" menuAlign="right" title="Account">
                <div>
                  <span className="icon pl-2 pr-4">
                  <FontAwesomeIcon icon={faCog} />
                  </span>
                  <span className="text">
                <Link className="bg-transparent" to="/settings">Settings</Link>
                </span>
                </div>
                <div>
                  <span className="icon pl-2 pr-4">
                    <FontAwesomeIcon icon={faSignOutAlt} />
                    </span>
                  <span className="text">
                    <Button variant="white" className="pl-0 bg-transparent" onClick={handleLogout}>Sign Out</Button>
                  </span>

                  
                </div>
              </DropdownButton>
            </Nav.Item>
                  {/* below are dashboard buttons only visible at sm screen sizes */}
            <Nav.Item className="d-sm-none">
              <div className="d-flex icon pt-3 pb-3">
                    <span className="icon pr-4">
                      <FontAwesomeIcon icon={faHome} />
                    </span>
                    <span className="text pl-1">
                      <Link to="/dashboard">Dashboard</Link>
                    </span>
                    </div>
            </Nav.Item>
            <Nav.Item className="d-sm-none">
              <div className="d-flex icon pb-3">
                    <span className="icon pr-4">
                      <FontAwesomeIcon icon={faTasks} />
                    </span>
                    <span className="text pl-1">
                      <Link to="/claims">Claims</Link>
                    </span>
                    </div>
            </Nav.Item>
            <Nav.Item className="d-sm-none">
                      <div className="d-flex icon pb-3">
                    <span className="icon pr-4">
                      <FontAwesomeIcon icon={faShippingFast} />
                    </span>
                    <span className="text pl-0">
                  <Link to="/orders">Orders</Link>
                    </span>
                  </div>
            </Nav.Item>
            <Nav.Item className="d-sm-none">
              <div className="d-flex icon pb-3">
                    <span className="icon pr-4">
                    <FontAwesomeIcon icon={faQuestionCircle} />
                    </span>
                    <span className="text pl-1">
                    <Link to="/guide" className="pb-3">Guide</Link>
                    </span>
                  </div>
            </Nav.Item>
            <Nav.Item className="d-sm-none">
              <div className="d-flex icon pb-3">
                    <span className="icon pr-4">
                    <FontAwesomeIcon icon={faUserCog} />
                    </span>
                    <span className="text pl-0
                    ">
                    <Link className="pb-3" to="/settings">Settings</Link>
                    </span>
                  </div>
            </Nav.Item>
            <Nav.Item className="d-sm-none">
              <div className="d-flex icon pb-3">
                    <span className="icon pr-4">
                    <FontAwesomeIcon icon={faSignOutAlt} />
                    </span>
                    <span className="text pl-1">
                    <Link className="pb-3" onClick={handleLogout}>Log Out</Link>
                    </span>
                  </div>
            </Nav.Item>
            </Nav>
            </Navbar.Collapse>
      </Container>
    </Navbar>
            </Styles>
    )
     } else {
    return (
         <Styles>
    <Navbar expand="lg">
      <Container>
      <Navbar.Brand className="pr-5" href="/">Swiftask</Navbar.Brand>
      <Navbar.Toggle aria-controls="basic-navbar-nav" />
      <Navbar.Collapse id="basic-navbar-nav">
        <Nav>
          <Nav>
            <Nav.Item>
             <Nav.Link className="pl-4 pr-4">
               <Link to="/">Home</Link>
             </Nav.Link>
           </Nav.Item>
           <Nav.Item>
             <Nav.Link className="pl-4 pr-4">
               <Link to="/about">About</Link>
             </Nav.Link>
           </Nav.Item>
           <Nav.Item>
             <Nav.Link className="pl-4 pr-4">
               <Link to="/contact">Contact</Link>
             </Nav.Link>
           </Nav.Item>
           <Nav.Item>
             <Nav.Link className="pl-4 pr-4">
               <Link to="/faq">FAQ</Link>
             </Nav.Link>
           </Nav.Item>
           <Nav.Item>
             <Nav.Link className="pl-4 pr-4">
               <Link to="/pricing">Pricing</Link>
             </Nav.Link>
           </Nav.Item>
           </Nav>
          </Nav>
           <Nav className="ml-auto d-flex">
          <Nav.Item className="pl-2 pr-4">
            <Button style = { colr } as={Link} to="/register" class="btn-props" variant="primary">Register
              </Button>
            </Nav.Item>
          <Nav.Item className="pl-4 pr-4">
            <Nav.Link>
              <Link to="/login">Login</Link>
            </Nav.Link>
            </Nav.Item>
            </Nav>
            </Navbar.Collapse>
            </Container>
            </Navbar>
    </Styles>
    );
}
}
;
