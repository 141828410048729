import { Jumbotron as Jumbo, Card, Col, Container, Button } from 'react-bootstrap';
import { Link } from 'react-router-dom'
import styled, { keyframes } from 'styled-components';
import { fadeInUp, bounce } from 'react-animations'
import businessman1 from '../assets/businessman1.jpg'


const fadeAnimationDown = keyframes`${bounce}`;

const FadeDownDiv = styled.div`
  animation: 1s ${fadeAnimationDown};
  h1 {
   font-weight: 800;
   letter-spacing: -1px; 
  }
  @media screen and (max-width: 768px) {
    h1 {
      font-size: 24px;
      font-weight: 700px;
    }
  }
`;
const fadeAnimationUp = keyframes`${fadeInUp}`;

const FadeUpDiv = styled.div`
  animation: 1s ${fadeAnimationUp};

`;


const Styles = styled.div`
  .jumbo {
    text-align: center;
    background-color: white;
    display: flex;
    flex-wrap: wrap;
    padding: 2rem 2rem 2rem;
  }
  p {
    line-height: 1;
  }
  .align-btn {
    padding-top: 10px;
  }
  .align-txt {
    flex-flow: column;
    justify-content: space-evenly;
    padding-bottom: 30px;

  }
  .img {
    width: 50px;
    height: 50px;
  }
  .card {
    box-shadow: 0 0 10px 0 rgba(100, 100, 100, 0.26);
  }
  .btn-props {
    box-shadow: 0 8px 16px 0 rgba(0,0,0,0.2), 0 6px 20px 0 rgba(0,0,0,0.19);
  }
  @media screen and (max-width: 768px) {
     .container {
       flex-direction: column;
      }
      .align-btn {
        padding-top: 20px;
      }
      div.col:nth-child(2) {
        padding-left: 0px;
        padding-right: -0px;
        }
      .jumbo {
        padding-left: 0px;
        padding-right: 0px;
      }
    };
     
  `;

  export const Jumbotron = () => (
  <Styles>
  <Jumbo className="jumbo">
    <Container className="d-flex">
    <Col className="d-flex align-txt">
      <FadeDownDiv>
        <h1>Pharmacy tasks</h1> 
        <h1>made easier</h1>
      </FadeDownDiv>
      <FadeUpDiv className="text-muted">
        <p>Overwhelmed by your claim and ordering?</p>
      <p>Let Swiftask deal with time consuming paperwork.</p>
      <p>Save time, money and work smarter with Swiftask.</p>
      <p>Finally you will have time to be a Pharmacist.</p>
      </FadeUpDiv>
      <Button as={Link} to="/register" className="btn-props" variant="danger">Sign up for free</Button> 
   </Col>
  <Col class="align-btn">
    <Card>
    <Card.Img className="todo" variant="top" src={businessman1} />
  </Card>
  </Col>
  </Container>
</Jumbo>
</Styles>
)
 