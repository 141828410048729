import { Container } from 'react-bootstrap';
import styled from 'styled-components'
import Feedback from 'feeder-react-feedback';
import "feeder-react-feedback/dist/feeder-react-feedback.css"
import { Link } from 'react-router-dom'

const Styles = styled.footer`
    {
    width: 100%;
    position: relative;
    bottom: 0;
    background-color: rgb(248, 249, 250);
    };
    .alignment{
        flex-direction: column;
        align-items: center;
        padding: 15px 0 10px;
    };
    .frf-water {
        display: none;
    }
    .frf-feedback-container {
        position: static;
}
`;


export const Footer = () => {
    return (
    <Styles>
        <Container>
        <footer>
            <div class="row justify-content-center">
                <div class="col-md-4 d-flex justify-content-end alignment">
                    <h5>
                        Swiftask
                    </h5>
                    <small class="d-block mb-3 text-muted">© 2021</small>
                </div>
                 <div class="col-md-4 d-flex justify-content-start alignment">
                      <h5>Contact</h5>
                      <div class="row">
                        <div class="mr-2">
                      <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-envelope text-muted" viewBox="0 0 16 16">
            <path d="M0 4a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2v8a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V4zm2-1a1 1 0 0 0-1 1v.217l7 4.2 7-4.2V4a1 1 0 0 0-1-1H2zm13 2.383l-4.758 2.855L15 11.114v-5.73zm-.034 6.878L9.271 8.82 8 9.583 6.728 8.82l-5.694 3.44A1 1 0 0 0 2 13h12a1 1 0 0 0 .966-.739zM1 11.114l4.758-2.876L1 5.383v5.73z"/>
                        </svg>
                        </div>
                            <div class="text-muted">info@swiftask.ie</div>
                      </div>
                      
                </div>
                <div class="col-md-4 d-flex justify-content-end alignment">
                    <h5>
                        Legal
                    </h5>
                    <small class="d-block"><Link className='text-muted' to='/privacy'>Privacy</Link></small>
                    <small class="d-block"><Link className='text-muted' to='/terms'>Terms and Conditions</Link></small>
                </div>
            </div>
            <Feedback 
    projectId="6285f116712224000473ab4c"
    primaryColor="#6c757d"
    email="true"/>
        </footer>
            
    
        </Container>
    </Styles>
    )
};
