import { Card, CardDeck, Spinner, Col, Row } from 'react-bootstrap'

export const DashboardLoading = () => (
	<CardDeck className='mb-3'>
  <Col sm={6}>
    <Card className='text-center'>
      <Card.Body style={{height: "152px"}}>
        <Card.Title style={{fontSize: "large"}} >Claims Summary</Card.Title>
          <hr></hr>
            <Card.Text>
              <div>
                <Spinner animation="border" variant="primary" />
              </div>
            </Card.Text>
        </Card.Body>
    </Card>
  </Col>
  <Col sm={6}>
  <Card className='text-center' style={{width: "265px", height: "152px"}}>
    <Card.Body>
      <Card.Title style={{fontSize: "large"}} >Most Common Errors</Card.Title>
        <hr></hr>
          <Card.Text>
            <div>
              <Spinner animation="border" variant="primary" />
            </div>
          </Card.Text>
    </Card.Body>
  </Card>
</Col>

</CardDeck>
)

export const TableLoading = () => (
	 <Row className="justify-content-md-center pt-2">
    <Col md="auto">
  <Card className='text-center' style={{width: "265px", height: "152px"}}>
          <Card.Body>
            <Card.Title style={{fontSize: "large"}} >Claims Listings</Card.Title>
              <hr></hr>
                <Card.Text>
                    <Spinner animation="border" variant="primary" />
                </Card.Text>
          </Card.Body>
        </Card>
    </Col>
  </Row>
)

export const StockDataLoading = () => (
	 <Row className="justify-content-md-center pt-2">
    <Col md="auto">
  <Card className='text-center' style={{width: "265px", height: "152px"}}>
          <Card.Body>
            <Card.Title style={{fontSize: "large"}} >Stock Data Loading</Card.Title>
              <hr></hr>
                <Card.Text>
                    <Spinner animation="border" variant="primary" />
                </Card.Text>
          </Card.Body>
        </Card>
    </Col>
  </Row>
)