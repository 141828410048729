import React from 'react';
import Checkbox from './checkbox';
import { useState, useEffect } from 'react';
import { Form, Card, Table, OverlayTrigger, Tooltip, Button } from 'react-bootstrap'
import { removeClaims } from './libs/awsLib'
import { useAppContext } from './libs/contextLib';
import { faSort, faQuestionCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

export const CheckboxContainer = (props) => {
	const [checkedItems, setCheckedItems] = useState(() => new Map());
	const [claimsToRemove, setClaimsToRemove] = useState([]);	
	const [claimProp, setClaimProp] = useState("claim")
	const { fetchData, dbClaimData } = useAppContext();
	const [sortedClaims, setSortedClaims] = useState(dbClaimData)
    const { reasonsForError, setReasonsForError } = useAppContext();
    // const { sortedClaims } = useAppContext();
	
	useEffect(() =>{
		const x = async () => {
			console.log('dbclaimdata changed')
			setSortedClaims(dbClaimData)

			// put claim as key and reason as value in reasonForError for all elements in dbClaimData
			let temp = {}
			dbClaimData.forEach(claim => {
				let x = claim.claim;
				x = x.toString()
				temp[x] = claim.reason
			}
			)
			setReasonsForError(temp)

		}
		x()
	}, [dbClaimData])

	useEffect(() => {
		const claimCheckChange = () => {
			setClaimsToRemove(() => {
				let claimsWithNoFalseValues = Array.from(checkedItems.entries());
				claimsWithNoFalseValues = claimsWithNoFalseValues.filter((x) => {
					if (x[1] === true) {
						return true;
					} else {
						return false;
					}
				})
				return claimsWithNoFalseValues;
			})
  			}

		claimCheckChange();
		// console.log("Claims to remove", claimsToRemove);
	}, [checkedItems])

	useEffect(() => {
		const sortArray = type => {
			
			if (type === "claim" || type === "sequence" || type === "item") {
			const sorted = [...sortedClaims].sort((a, b) => b[claimProp] - a[claimProp])
			console.log(sorted);
			setSortedClaims(sorted);
			} else if (type === "status" || type === "scheme") {
				
				const sorted = [...sortedClaims].sort((a, b) => b[claimProp] > a[claimProp])
				setSortedClaims(sorted);
			} else {

				const sorted = [...sortedClaims].sort((a, b) => 
				new Date(b.date) - new Date(a.date))
				setSortedClaims(sorted);
			}
		};
		sortArray(claimProp);
	}, [claimProp])

	// useEffect hook that logs to console when reasonsForError changes
	// useEffect(() => {
	// 	console.log(reasonsForError)
	// }, [reasonsForError])

	const handleChange = e => {
		const item = e.target.name;
		console.log(checkedItems);
		const ischecked = e.target.checked;
		setCheckedItems(prevList => {
			const newList = new Map(prevList);
			newList.set(item, ischecked);
			return newList;
	})
	}

	const handleSubmit = async (e) => {
		e.preventDefault();
		
		console.log(JSON.stringify(claimsToRemove));

		const remove = await removeClaims(JSON.stringify(claimsToRemove));
		console.log(remove);
		fetchData();
		console.log(props.dbdata)
		setClaimsToRemove([]);
		setCheckedItems(() => new Map());
	}
	// an input button that updates the value of 'reason' in the object
	// containing item.claim  in the array sortedClaim using setSortedClaim hook
	const handleReasonChange = (e) => {
		const item = e.target.name;
		const reason = e.target.value;
		const newClaims = sortedClaims.map((x) => {
			if (x.claim === item) {
				x.reason = reason;
				return x;
			} else {
				return x;
			}
		})

		let claimStr = item.toString()
		setReasonsForError({
			...reasonsForError,
			[claimStr]: reason
		})
		setSortedClaims(newClaims)
		}
		
	
	

		




	return (
		<>
        <Form onSubmit={handleSubmit}>
        <Card className='mb-3'>
          <Table size="sm"  hover responsive="sm" className='mb-0'>
            <thead>
              <tr>
                <th><div onClick={() => setClaimProp('claim')} type='button'>Claim
				<span className='pl-1'>
				<FontAwesomeIcon icon={faSort} size='lg' />
				</span>
				</div>
				</th>
                <th>
					<div onClick={() => setClaimProp("date")} type='button'>Date
				<span className='pl-1'>
				<FontAwesomeIcon icon={faSort} size='lg' />
				</span>
				</div>
				</th>
				<th>
					<div className='d-flex' onClick={() => setClaimProp("scheme")} type='button'>Scheme
				<span className='pl-1'>
				<FontAwesomeIcon icon={faSort} size='lg' />
				</span>
				</div>
				</th>
                <th>
					<div className='d-flex' onClick={() => setClaimProp("sequence")} type='button'>Sequence
				<span className='pl-1'>
				<FontAwesomeIcon icon={faSort} size='lg' />
				</span>
				</div>
				</th>
                <th>
					<div onClick={() => setClaimProp("item")} type='button'>Item
				<span className='pl-1'>
				<FontAwesomeIcon icon={faSort} size='lg' />
				</span>
				</div>
				</th>
                <th>Error</th>
                <th>
					<div className='d-flex' onClick={() => setClaimProp("status")} type='button'>Status
				<span className='pl-1'>
				<FontAwesomeIcon icon={faSort} size='lg' />
				</span>
				</div>
				</th>
                <th>
                  <OverlayTrigger overlay={<Tooltip id="tooltip-disabled">Check claim boxes and click Remove</Tooltip>}>
                    <span>
                	{claimsToRemove.length > 0?  
			<div type="submit" onClick={handleSubmit}>Remove</div>
			:
			<div style={{color: 'gray'}}>Remove</div>
			}
                    </span>
                  </OverlayTrigger>
                </th>
				<th>
					<div>Reason
						<span className='pl-1'>
                  <OverlayTrigger overlay={<Tooltip id="tooltip-disabled">Add reason for error to ensure payment from PCRS. This will be added to PCRS Query Form</Tooltip>}>
				<FontAwesomeIcon  icon={faQuestionCircle} size='lg' />
				</OverlayTrigger>
				</span>
					</div>
				</th>
              </tr>
		</thead>
 		<tbody>
	      {sortedClaims.map(item => (
		      <tr>
			      <td>{item.claim}</td>
			      <td>{props.datefix(item.date)}</td>
				  <td>{item.scheme}</td>
			      <td>{item.sequence}</td>
			      <td>{props.concatitems(item.item)}</td>
			      <td>{item.error}</td>
			      <td style={{backgroundColor: item.status === 'paid'? props.statusStyle.paid.backgroundColor : props.statusStyle.unpaid.backgroundColor,
                                color: item.status === 'paid'? props.statusStyle.paid.color : props.statusStyle.unpaid.color}}>
                      		{item.status}</td>
				<td><Checkbox name={props.concatClaimSequence(item.claim, item.sequence)} checked={checkedItems.get(props.concatClaimSequence(item.claim, item.sequence))} onChange={handleChange} />
				</td>
				<td><div><input
					 type="text"
					 placeholder={item.reason || 'Enter reason'}
					 name={item.claim}
					 value={item.reason}
					 onChange={handleReasonChange}
					 /></div></td>
		      </tr>
	      ))
	      }
         </tbody>
          </Table>
        </Card>
        </Form>
		</>
	      )
};