import React from "react";
import styled from 'styled-components'
import { Link } from 'react-router-dom'
import { Row, Col, Button } from 'react-bootstrap';
import { faClipboardCheck, faChartLine, faLock } from '@fortawesome/free-solid-svg-icons';
import { Icon } from './ClipboardIcon'

const Styles = styled.div`
  .btn-props {
        box-shadow: 0 8px 16px 0 rgba(0,0,0,0.2), 0 6px 20px 0 rgba(0,0,0,0.19);
        margin-bottom: 15px;
    }
    }
    .bottom-col {
        margin-bottom: 30px;
    }
    .bottom-row {
      margin-bottom: -10px;
    }
    h3 {
        margin-bottom: 30px;
        font-weight: 600;
        letter-spacing: -0.048em;
    }
    @media screen and (max-width: 768px) {
        h3 {
            font-size: 24px;
            font-weight: 600;
        }
    ;
`
;

const ClipboardIcon = {
  header : "Reduce your workload instantly",
  paragraph: "you won't know yourself with all the free time you have",
  faIcon: faClipboardCheck,
  color: "lightgreen"
};

const AnalysisIcon = {
  header: "Get valuable business insights",
  paragraph: "Optimise your business to become more profitable",
  faIcon: faChartLine,
  color: "lightcoral"
};
const SecurityIcon = {
  header: "Security is our priority",
  paragraph: "Your data is safe and secure",
  faIcon: faLock,
  color: "darkgray"
};

export const DataFlowStock = () => {
  return (
    <Styles>
    <Row className="bottom-row pt-3">
               <Col xs={12} lg={6}>               
               <Icon color={ClipboardIcon.color} header={ClipboardIcon.header} paragraph={ClipboardIcon.paragraph} faIcon={ClipboardIcon.faIcon} />
               </Col>
               <Col xs={12} lg={6}>
               <Icon color={AnalysisIcon.color} header={AnalysisIcon.header} paragraph={AnalysisIcon.paragraph} faIcon={AnalysisIcon.faIcon} />
               </Col>
            </Row>
            <Row>
                <Col>
               <Icon color={SecurityIcon.color} header={SecurityIcon.header} paragraph={SecurityIcon.paragraph} faIcon={SecurityIcon.faIcon} />
               </Col>
           </Row>
           <Col className="bottom-col col-12 text-center">
            <h3>Start freeing up time straight away</h3>
            <Button as={Link} to="/register" className="btn-props text-center" variant="danger">Try out one month for free</Button> 
            </Col>
    </Styles>
  );
}