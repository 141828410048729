import styled from 'styled-components'
import { Link } from 'react-router-dom'
import { Row, Card, Accordion, Button, Col } from 'react-bootstrap';
import UpgradedButton from './components/UpgradedButton';
import { faClipboardCheck, faChartLine, faLock } from '@fortawesome/free-solid-svg-icons';
import { Icon } from './components/ClipboardIcon'
import { Helmet } from 'react-helmet'

const Styles = styled.div`
    // text-align: left;
    h3 {
        margin-bottom: 30px;
        font-weight: 600;
        letter-spacing: -0.048em;
    }
    .heading {
        margin: 40px 0px 15px;
        text-align: center;
        font-family: "Open Sans", sans-serif;
        font-size: 42px;
        font-weight: 700;
        letter-spacing: -1px;
        line-height: 32px;
    }
    .bottom-col {
        margin-bottom: 30px;
    }
    @media screen and (max-width: 768px) {
        h3 {
            font-size: 24px;
            font-weight: 600;
        }
    }
    .card-body {
        border-bottom: 1px solid rgba(0,0,0,.125);
    }
    .btn-props {
        box-shadow: 0 8px 16px 0 rgba(0,0,0,0.2), 0 6px 20px 0 rgba(0,0,0,0.19);
        margin-bottom: 15px;
    }
    .accordion {
        padding: 20px;
    }
    .card {
        margin: 10px;
        border-bottom: 1px solid;
        &:hover {
            box-shadow: 0 0 10px 0 rgba(100, 100, 100, 0.26);
        }
    }
    @media screen and (max-width: 768px) {
        .btn-link {
            text-align: initial;   
        }
        .heading {
        font-size: 30px;
        }
    }
`;

const ClipboardIcon = {
    header : "Reduce your workload instantly",
    paragraph: "you won't know yourself with all the free time you have",
    faIcon: faClipboardCheck,
    color: "lightgreen"
};

const AnalysisIcon = {
    header: "Get valuable business insights",
    paragraph: "Optimise your business to become more profitable",
    faIcon: faChartLine,
    color: "lightcoral"
};
const SecurityIcon = {
    header: "Security is our priority",
    paragraph: "Your data is safe and secure",
    faIcon: faLock,
    color: "darkgray"
};


const FaqPage = () => {
    return (
        <Styles>
            <Helmet>
                <title>FAQ</title>
            </Helmet>
            <h2 className="heading">Simplify pharmacy paperwork</h2>
            <Col className="text-center text-muted"><p>Let Swiftask handle the repetitive tasks.</p></Col>
           <UpgradedButton />
           <Row>
               <Col xs={12} lg={6}>               
               <Icon color={ClipboardIcon.color} header={ClipboardIcon.header} paragraph={ClipboardIcon.paragraph} faIcon={ClipboardIcon.faIcon} />
               </Col>
               <Col xs={12} lg={6}>
               <Icon color={AnalysisIcon.color} header={AnalysisIcon.header} paragraph={AnalysisIcon.paragraph} faIcon={AnalysisIcon.faIcon} />
               </Col>
            </Row>
            <Row>
                <Col>
               <Icon color={SecurityIcon.color} header={SecurityIcon.header} paragraph={SecurityIcon.paragraph} faIcon={SecurityIcon.faIcon} />
               </Col>
           </Row>
            <h2 className="heading">Frequently Asked Questions</h2>
    <Accordion defaultActiveKey="0">
    <Card>
        <Card.Header>
        <Accordion.Toggle as={Button} variant="link" eventKey="0">
           What is Swiftask? 
        </Accordion.Toggle>
        </Card.Header>
        <Accordion.Collapse eventKey="0">
        <Card.Body className="text-muted">
            Swiftask is a web application that simplifies and automates Pharmacy tasks.
        </Card.Body>
        </Accordion.Collapse>
    </Card>
    <Card>
        <Card.Header>
        <Accordion.Toggle as={Button} variant="link" eventKey="1">
            What tasks can you help me with?
        </Accordion.Toggle>
        </Card.Header>
        <Accordion.Collapse eventKey="1">
        <Card.Body className="text-muted">
            Stock ordering. Instantly generate the following:
            <ul>
                <li>PCO order</li>
                <li>Generic order</li>
                <li>Breakdown of percentages of stock which are generic</li>
            </ul>
            Claim reconciliation. You will be able to:
            <ul>
                <li>Keep track of all rejects and reclaims.</li>
                <li>Monitor Hardship payments</li>
                <li>Automatically generates PCRS query form for unpaid claims</li>
            </ul>
        </Card.Body>
        </Accordion.Collapse>
    </Card>
    <Card>
        <Card.Header>
        <Accordion.Toggle as={Button} variant="link" eventKey="1">
            How does Swiftask work?
        </Accordion.Toggle>
        </Card.Header>
        <Accordion.Collapse eventKey="1">
        <Card.Body className="text-muted">
            <p>It's really easy. For orders, upload a stock report to Swiftask, you will have your PCO and generic order in seconds.</p>
            <p>For claim payments, just upload the GMS and DPS claim files from the PCRS website, or multiple months if you want.</p>
            Repeat these steps each month and your worklife will be a lot easier! 
        </Card.Body>
        </Accordion.Collapse>
    </Card>
    <Card>
        <Card.Header>
        <Accordion.Toggle as={Button} variant="link" eventKey="1">
            Is my data safe?
        </Accordion.Toggle>
        </Card.Header>
        <Accordion.Collapse eventKey="1">
        <Card.Body className="text-muted">
            <p>Yes, all files you upload are involved in a one time process to get the information needed to generate orders and claim reject data.
                Your claim files or orders are not stored on the server nor are they distributed to any third parties. <a href="#">Privacy Statement</a>
            </p>
        </Card.Body>
        </Accordion.Collapse>
    </Card>
    <Card>
        <Card.Header>
        <Accordion.Toggle as={Button} variant="link" eventKey="1">
            Where do I go if I have more question?
        </Accordion.Toggle>
        </Card.Header>
        <Accordion.Collapse eventKey="1">
        <Card.Body className="text-muted">
            <p>If you have any more question you can contact info@swiftask.ie
            </p>
        </Card.Body>
        </Accordion.Collapse>
    </Card>
        </Accordion>
        <Col className="bottom-col col-12 text-center">
            <h3>Start freeing up time straight away</h3>
            <Button as={Link} to="/register" className="btn-props text-center" variant="danger">Try out one month for free</Button> 
            </Col>
    </Styles>
    )
}

export default FaqPage;   
