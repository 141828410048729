import { Form, Col, Row, Card, Accordion, Button, CloseButton } from 'react-bootstrap'
import { SideBar } from "./components/Sidebar"
import LoaderButton from './components/LoaderButton';
import React, { useRef, useState} from "react";
import { onError } from "./libs/errorLib";
import { s3MultiFileUpload, claimsAPI, pollTable } from './libs/awsLib';
import { Storage } from 'aws-amplify';
import styled, { keyframes } from 'styled-components'
import { bounceIn } from 'react-animations';
import dialogbox from "./assets/dialogbox.png"
import pcrsReporting from "./assets/pcrsReporting.png"
import { Helmet } from 'react-helmet'
import { Checkmark } from 'react-checkmark'
import queryFormSend from "./assets/queryFormSend.gif"
import vatFiles from "./assets/vatFiles.gif"
import stockUpload from "./assets/stockUpload.gif"
import openStockOrder from "./assets/openStockOrder.gif"
import uploadClaim from "./assets/uploadClaim.gif"


const swingAnimation = keyframes`${bounceIn}`;

const Styles = styled.div`
  .Form {
    margin: 0 auto;
    max-width: 320px;
  }
  .file-border {
    border: 1px solid #dee2e6;
    width: max-content;
  }
  .head {
    font-weight: 550;
    border-bottom: 1px solid #dee2e6; 
  }
  .s3link {
    font-size: 15px;
    animation: 1s ${swingAnimation};
    color: #007bff;
  }
  .checkmark {
    margin-left: 0px;
  }
`;

export default function Claims() {
    const file = useRef(null);
    const [isLoading, setIsLoading] = useState(false);
    const [signedURL, setSignedUrl] = useState('');
    const [claimFiles, setClaimFiles] = useState({});
    const [isPolling, setIsPolling] = useState(false)
    const MAX_ATTACHMENT_SIZE = 5000000;

  function handleFileChange(event) {
    file.current = event.target.files;
    console.log(claimFiles)
    
    // log all file names to console

    let dateObj = {}
    for (let i = 0; i < file.current.length; i++) {
      let fileName = file.current[i].name.split("_")[0];
      // let month be last two letters of file name
      let month = fileName.slice(-2);
      let year = fileName.slice(0,4);
      let date = `${month}-${year}`;
      console.log("date:", date);
      // put date in an object with current count of date
      dateObj[date] = dateObj[date] + 1 || 1;
    }
    console.log("dateObj:", dateObj);
    setClaimFiles(dateObj);
  };

  // dateObj: {08-2021: 1, 09-2021: 2, 10-2021: 1}
  const bothFilesPresent = (dateObj) => {
    // check if the values of all keys in dateObj are greater than 1
    let keys = Object.keys(dateObj);
    let values = Object.values(dateObj);
    let result = true;
    for (let i = 0; i < values.length; i++) {
      if (values[i] < 2) {
        result = false;
      }
    }
    return result;
  } 

  async function handleSubmit(event) {
    event.preventDefault();

    if (file.current && file.current.size > MAX_ATTACHMENT_SIZE) {
      alert(
        `Please pick a file smaller than ${MAX_ATTACHMENT_SIZE /
          1000000} MB.`
      );
      return;
    }

    setIsLoading(true);

    const checkTaskStatus = async () => {
      const checkTask = await pollTable();
      console.log('apicallfile: ', checkTask)
      let status = await checkTask.Item.taskFinished;
      setIsPolling(true)
        if (status === 'false') {
            console.log('Task not finished')
            console.log('Sleeping for 2 seconds...')
            const sleep = ms => new Promise(r => setTimeout(r, ms));
            await sleep(2000);

            checkTaskStatus()

        } else {
            console.log('Task has finished')

            setIsPolling(false)
            const theURL = await Storage.get("zipped_claim_data.zip", {level: 'private', contentType: 'string', download: "true"}); // get key from Storage.list
            console.log("TheURL: ", theURL);
            setSignedUrl(theURL);
            setIsLoading(false);
        }
    };



    try {
    const attachment = await s3MultiFileUpload(file.current);
    console.log({ attachment });
    const apiCallFile = file.current ? await claimsAPI(attachment.join()) : null;
    
    // get the signed URL string
    const theURL = await Storage.get(apiCallFile, {level: 'private', contentType: 'string', download: "true"}); // get key from Storage.list
    console.log("TheURL: ", theURL);
    setSignedUrl(theURL);

    setIsLoading(false);
    } catch (e) {
      console.log(e.response.statusText)
      if (e.response.statusText === "Gateway Timeout") { 
        console.log("API has timed out")
        // logic to poll DB for finished task
        await checkTaskStatus();
        

      } else {
      onError(e);
      setIsLoading(false);
    }
  }
  }
    return (
    <Row className=" pl-1 pt-3 pb-3">
      <Helmet>
                <title>Claims</title>
            </Helmet>
        <Col className="d-none d-sm-block sidebar-width pt-3 border border-grey rounded sidebar-height sticky-top" sm={2}> 
        <SideBar />
        </Col>
        <Col className="align-self-center">
    <Styles>
  <Accordion>
  <Card>
    <Card.Header>
      <Accordion.Toggle as={Button} variant="link" eventKey="0">
        How to use
      </Accordion.Toggle>
    </Card.Header>
    <Accordion.Collapse eventKey="0">
      <Card.Body>
        <Card.Title>
          Generating Your Claim Reports
        </Card.Title>
        <div className="text-muted">
          <p>In your web browser, go to PCRS Pharmacy Suite.</p>
          <p>Click on Reporting on the left menu panel and then click on GMS dispensing.</p>
          <p>The screen should now look similar to the one below.</p>
          <Card.Img src={pcrsReporting} variant="top" />
          <p>For every claim month you need to download both the <b>GMS itemised claim</b> and the <b>DPS itemised claim</b>.</p>

          <p><b>Important: </b>When saving the file <b>make sure you don't alter the file name, just click save</b>.</p>
          <p>Methadone claims analysis is not currently supported. </p>
          <p>Once your files are saved to your computer you can upload them by clicking the button below.</p>
          <p>This dialog box should pop up. Make sure you select both GMS and DPS files when uploading.</p> 
          <Card.Img src={dialogbox} variant="top" />
          <p>To upload multiple files hold the shift key and press down or up to complete selection.</p>
          <p>For instance, if you wanted to upload the June Claim, you should select 202006_12345_statement.pdf and 202006_12345_statement(1).pdf using the shift key.</p>
          <div className='text-center'>
            <img src={uploadClaim} 
              alt="gif"
              height="300"
              width="500"
                />
          </div>
          <p>Your report should be generated in a few seconds for you to download or view and interact with in the Dashboard.</p>
          <p>On the Dashboard you will see your claim data in a table. If you want to add reasons to rejected claims you can. This will show up the PCRS Query Form when you generate it.</p>
          <p>You can also delete any claim by selecting the claim and clicking on the remove button.</p>

          <div className='text-center'>
            <img src={queryFormSend} 
            alt="gif"
            height="300"
            width="500" />
          </div>
          <p>Once you generate your form you can print it and send it to the PCRS to claim payment for rejects.</p>
          <p>You can also select any month you have uploaded to retrieve VAT files. Very handy for your accountant.</p>

          <div className='text-center mb-2'>
            <img src={vatFiles} 
            alt="gif"
            height="300"
            width="500" />
          </div>
          <p>Note: multiple files will take a bit longer to process.</p>
        </div>
      </Card.Body>
    </Accordion.Collapse>
  </Card>
  </Accordion>
    <Form className="text-center pt-4 pb-3" onSubmit={handleSubmit}>
        <Form.Group className="pb-3" controlId="file">
          <Form.Label><h5>Click to upload Claim files</h5></Form.Label>
          <Form.Control className="text-center" onChange={handleFileChange} multiple type="file" />
        </Form.Group>
        <LoaderButton
          className="mb-3"
          type="submit"
          size="lg"
          variant="primary"
          isLoading={isLoading}
          disabled={!bothFilesPresent(claimFiles)}
        >
          Upload
        </LoaderButton>
        { isPolling &&
                <div>
                  <div>Generating your Claim report is taking longer than normal.</div>
                    <div>Please do not refresh.</div>
                  </div>
              }
      </Form>     
      
      <div className='col'>
      {
        // display all values of claimFiles object in a table
        !signedURL && claimFiles && Object.keys(claimFiles).map(key => (
          claimFiles[key] > 1 ?
          <Row className='pb-2'>
        <Col className='text-right'>{key} GMS and DPS files present
              </Col>
              <Col>
              <Checkmark size='medium' />
              </Col>
          </Row>
          :
          <>
          <Row className='pb-2'>
        <Col className='text-right'>{key} One file missing</Col>
              <Col>
              <CloseButton style={{float: 'left', color: 'red'}} variant="info" />
              </Col>
          </Row>
          </>
        ))
      }
      </div>

        { signedURL ? 
          <Row className="pt-2 pb-4">
            <Col md={{ span: 6, offset: 2 }}>
          <div className="file-border pt-1 pb-1">
            <div className="head text-center pt-1 pb-1 pl-2 pr-2">
            Click below to download Claim Data
            </div>
            <div className="s3link text-center pt-1 pb-1 pl-2 pr-2">
              <a className="s3link" href={signedURL} target="_blank">Download Claim Report files</a>
            </div>
        </div>
        </Col>
        </Row>
          : null }
        </Styles>
    </Col>
    </Row>
    )
        }     