import React from "react";
import { Link } from "react-router-dom";
import { Button, Col, Row } from 'react-bootstrap'
import styled from 'styled-components'
import { SideBar } from "./components/Sidebar";
import { Helmet } from 'react-helmet';

const Styles = styled.div`
    .heading {
        margin: 20px 0px 15px;
        text-align: center;
        font-family: "Open Sans", sans-serif;
        font-size: 42px;
        font-weight: 700;
        letter-spacing: -1px;
        line-height: 32px;
    }
    @media all and (min-width: 480px) {
  .Settings {
    padding: 20px 0;
    margin: 0 auto;
    max-width: 480px;
  }

  .Settings > .LoaderButton:first-child {
    margin-bottom: 15px;
  }
}

`;

export default function Settings() {

   return (
      <Row className="pl-2 pt-3 pb-3">
        <Helmet>
                <title>Settings</title>
            </Helmet>
        <Col className="d-none d-sm-block pt-3 border border-grey rounded sidebar-height sticky-top" sm={2}> 
        <SideBar />
        </Col>
        <Col>
      <Styles>
          <h2 className="heading">Settings</h2>
      <div className="Settings">
          <Col className="text-center mx-auto">
          <Button as={Link}  to="/password" variant="outline-primary">Change Password</Button>
          </Col>
    <hr />
  </div>
  </Styles>
  </Col>
  </Row>
  );
}