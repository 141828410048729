import styled from 'styled-components'
import { Link } from 'react-router-dom'
import { Container, Nav, Navbar } from 'react-bootstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {faShippingFast, faTasks, faQuestionCircle } from '@fortawesome/free-solid-svg-icons';

const Styles = styled.div`
    // margin: 20px;
    // .display-text {
        // font-weight: 700;
    // .col-sm-2 {
      // padding-top: 25px;
    // }
    
    a, .navbar-nav .nav-link {
      font-family: "Open Sans",sans-serif;
      color: rgb(33, 37, 41);
      &:hover {
        color: #007bff;
        text-decoration: none;
    }
  }
`
;

export const SideBar = () => {
    return (
      <Styles>
        <Navbar className="d-none d-sm-block" collapseOnSelect expand="lg">
          <Container>
          {/* <Navbar.Brand href="/dashboard">Dashboard</Navbar.Brand> */}
          {/* <Navbar.Toggle aria-controls="basic-navbar-nav" />
          <Navbar.Collapse id="basic-navbar-nav">  */}
               <Nav className=" flex-column pl-4">
                  <Link className="pt-3 pb-3 text-center" to="/dashboard">Dashboard</Link>
                  
                  <div className="d-flex icon pt-3 pb-3 border-top border-grey">
                    <span className="icon pr-4">
                      <FontAwesomeIcon icon={faTasks} />
                    </span>
                    <span className="text pl-1">
                      <Link to="/claims">Claims</Link>
                    </span>
                    
                  </div>
                  <div className="d-flex icon pb-3">
                    <span className="icon pr-4">
                      <FontAwesomeIcon icon={faShippingFast} />
                    </span>
                    <span className="text pl-0">
                  <Link to="/orders">Orders</Link>
                    </span>
                  </div>
                  <div className="d-flex icon pb-3">
                    <span className="icon pr-4">
                    <FontAwesomeIcon icon={faQuestionCircle} />
                    </span>
                    <span className="text pl-1">
                    <Link to="/guide" className="pb-3">Guide</Link>
                    </span>
                  </div>
                </Nav>
                {/* </Navbar.Collapse> */}
                </Container>
         </Navbar>
          </Styles>
    );
};


// <Navbar expand="lg">
//           <Container>
//           {/* <Navbar.Toggle aria-controls="basic-navbar-nav" />
//           <Navbar.Collapse id="basic-navbar-nav"> */}
//                <Nav className=" flex-column pl-4">
//                   <Link className="pt-3 pb-3 text-center" to="/dashboard">Dashboard</Link>
                  
//                   <div className="d-flex icon pt-3 pb-3 border-top border-grey">
//                     <span className="icon pr-4">
//                       <FontAwesomeIcon icon={faTasks} />
//                     </span>
//                     <span className="text pl-1">
//                       <Link to="/claims">Claims</Link>
//                     </span>
                    
//                   </div>
//                   <div className="d-flex icon pb-3">
//                     <span className="icon pr-4">
//                       <FontAwesomeIcon icon={faShippingFast} />
//                     </span>
//                     <span className="text pl-0">
//                   <Link to="/orders">Orders</Link>
//                     </span>
//                   </div>
//                   <div className="d-flex icon pb-3">
//                     <span className="icon pr-4">
//                     <FontAwesomeIcon icon={faQuestionCircle} />
//                     </span>
//                     <span className="text pl-1">
//                     <Link className="pb-3">Guide</Link>
//                     </span>
//                   </div>
//                 </Nav>
//                 {/* </Navbar.Collapse> */}
//                 </Container>
//          </Navbar>
