import { Row, Col, Card, Table } from 'react-bootstrap'
export const StockData = (props) => {

	// Sorting data by percentage compliance - descending
	const sortObject = (obj) => {
		var sortable = [];
		for (var drug in obj) {
    			sortable.push([drug, obj[drug]]);
			}

		sortable.sort(function(a, b) {
    		return b[1] - a[1];
		});
		
		return sortable;
	}

	// ACETYLSALYCYLIC_ACID_75MG -> Acetylsalicylic Acid 75mg
	const transformText = (drug) => { 
		
		const capitalize = s => s && s[0].toUpperCase() + s.slice(1)
	
		drug = drug.split('_')
		drug = drug[0] + " " + drug[1]
		drug = capitalize(drug.toLowerCase())

		return drug
	}

	const drugDataSorted = sortObject(props.stockPercentagesData)

	// getting colour for background
	function getGreenToRed(percent){
             let r = percent<50 ? 255 : Math.floor(255-(percent*2-100)*255/100);
             let g = percent>50 ? 255 : Math.floor((percent*2)*255/100);
             return 'rgb('+r+','+g+',0)';
        }

	return (
		<Row className="pt-2 pb-2 justify-stock">
		<Col md="12">
	      <Card className='text-center'>
	      {/* <Card className='text-center' style={{width: "265px", height: "152px"}}> */}
		      <Card.Body className="table-pad">
			<Card.Title style={{fontSize: "large"}} >Stock Data</Card.Title>
			    <Card.Text style={{fontSize: "12px"}} className='mb-2'>
					<Table size="sm" responsive="sm" style={{height: '250px', overflow: 'auto'}}>
						<thead>
							<tr>
								<th>Drug</th>
								<th>Generic Percentage</th>
							</tr>
						</thead>
						<tbody>
							{drugDataSorted.map((drugKeyName, i) => (
								<tr>
									<td>{transformText(drugKeyName[0])}</td>
									<td style={{backgroundColor: getGreenToRed(drugKeyName[1])}}>{drugKeyName[1]}</td>
								</tr>
							)
							)}
						</tbody>
					</Table>
			    </Card.Text>
		      </Card.Body>
		    </Card>
		</Col>
	      </Row>
	)
}