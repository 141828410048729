import styled from 'styled-components'
import { Button, Accordion, Card } from 'react-bootstrap' 
import { Link } from 'react-router-dom'
import { Helmet } from 'react-helmet'
import dialogbox from "./assets/dialogbox.png"
import pcrsReporting from "./assets/pcrsReporting.png"
import stockhist2 from "./assets/stockhist2.png"
import stockhistory1 from "./assets/stockhistory1.png"
import uploadClaim from "./assets/uploadClaim.gif"
import queryFormSend from "./assets/queryFormSend.gif"
import vatFiles from "./assets/vatFiles.gif"
import stockUpload from "./assets/stockUpload.gif"
import openStockOrder from "./assets/openStockOrder.gif"

const Styles = styled.div`
    margin: 20px;
    .display-text {
        font-weight: 700;
    }
`
;

export const Guide = () => {
    return (
        <Styles>
            <Helmet>
                <title>Guide</title>
            </Helmet>
        <div>
        <div class="px-3 py-3 pb-md-4 mx-auto text-center">
        <h1 className="display-text">Guide</h1>
        <p class=" text-muted">Haven't checked your claim listings in a few months? Don't have the time to spend rooting around for rejects, reclaims, hardship payments <b>and</b> get your stock order sent?</p>
        <p class=" text-muted">Accountant looking for all your VAT files for the year?</p>
        <p class=" text-muted">We've got you covered. Swiftask will have all this and more available to you in a few seconds.</p>
        <p class="text-muted">This is a guide to help you get started with the basics of using Swiftask.</p>
        </div>

        <div class="container">
        <h3 className="display-text text-center">Claims</h3>
        <p><b>What do I need to do?</b></p>
        <p className='text-muted'>Upload one or more PCRS Claim files (Both GMS and DPS) and wait a few seconds, its that simple!</p>
        <p><b>What happens next?</b></p>
        <ul>
            <li className='text-muted'>Swiftask will automatically parse the files and extract the relevant information.</li>
            <li className='text-muted'>You will get a zip file with:
                <ul>
                    <li className='text-muted'>All rejects and paid reclaims</li>
                    <li className='text-muted'>All hardship payments</li>
                    <li className='text-muted'>The number of each type of rejects</li>
                    <li className='text-muted'>VAT and Summary Pages for every month uploaded - Handy for your Accountant!</li>
                    <li className='text-muted'>A PCRS Query Form pre-populated with all claim information</li>

                </ul>
                </li>
            <li className='text-muted'>All this data will also will be available in the Dashboard</li>
            <li className='text-muted'>You can interact with your rejects and add reasons for error to each one and can generate your PCRS Query Form with all reasons</li>
            <li className='text-muted'>You can also download your PCRS Query Form as a PDF</li>
            <li className='text-muted'>You can also select VAT files to download for any months you have uploaded</li>
        </ul>
        <p><b>Show me how it works!</b></p>
        <Accordion>
  <Card>
    <Card.Header>
      <Accordion.Toggle as={Button} variant="link" eventKey="0">
        How to use
      </Accordion.Toggle>
    </Card.Header>
    <Accordion.Collapse eventKey="0">
      <Card.Body>
        <Card.Title>
          Generating Your Claim Reports
        </Card.Title>
        <div className="text-muted">
          <p>In your web browser, go to PCRS Pharmacy Suite.</p>
          <p>Click on Reporting on the left menu panel and then click on GMS dispensing.</p>
          <p>The screen should now look similar to the one below.</p>
          <Card.Img src={pcrsReporting} variant="top" />
          <p>For every claim month you need to download both the <b>GMS itemised claim</b> and the <b>DPS itemised claim</b>.</p>

          <p><b>Important: </b>When saving the file <b>make sure you don't alter the file name, just click save</b>.</p>
          <p>Methadone claims analysis is not currently supported. </p>
          <p>Once your files are saved to your computer you can upload them by clicking the button below.</p>
          <p>This dialog box should pop up. Make sure you select both GMS and DPS files when uploading.</p> 
          <Card.Img src={dialogbox} variant="top" />
          <p>To upload multiple files hold the shift key and press down or up to complete selection.</p>
          <p>For instance, if you wanted to upload the June Claim, you should select 202006_12345_statement.pdf and 202006_12345_statement(1).pdf using the shift key.</p>
          <div className='text-center'>
            <img src={uploadClaim} 
              alt="gif"
              height="300"
              width="500"
                />
          </div>
          <p>Your report should be generated in a few seconds for you to download or view and interact with in the Dashboard.</p>
          <p>On the Dashboard you will see your claim data in a table. If you want to add reasons to rejected claims you can. This will show up the PCRS Query Form when you generate it.</p>
          <p>You can also delete any claim by selecting the claim and clicking on the remove button.</p>

          <div className='text-center'>
            <img src={queryFormSend} 
            alt="gif"
            height="300"
            width="500" />
          </div>
          <p>Once you generate your form you can print it and send it to the PCRS to claim payment for rejects.</p>
          <p>You can also select any month you have uploaded to retrieve VAT files. Very handy for your accountant.</p>

          <div className='text-center'>
            <img src={vatFiles} 
            alt="gif"
            height="300"
            width="500" />
          </div>
          <p>Note: multiple files will take a bit longer to process.</p>
        </div>
      </Card.Body>
    </Accordion.Collapse>
  </Card>
  </Accordion>
        <p className='mt-3'><b>How do I get started?</b></p>
        <Link to="/claims">Click here</Link>
        </div>
        <div className='container'>
          <h3 className="display-text text-center mt-2">Stock Orders</h3>
            <p><b>What do I need to do?</b></p>
            <p className='text-muted'>Upload a dispensing report to Swiftask selecting your preferred generic supplier.</p>
            <p><b>What happens next?</b></p>
            <ul>
                <li className='text-muted'>Swiftask will automatically parse the file and extract the relevant information.</li>
                <li className='text-muted'>You will get a zip file with:
                    <ul>
                        <li className='text-muted'>A PCO order list based on what you dispensed.</li>
                        <li className='text-muted'>A Generic order list for your preferred supplier based on what you dispensed.</li>
                    </ul>
                    </li>
                <li className='text-muted'>If you navigate back to the Dashboard you will have compliance data for every ingredient dispensed.</li> 
                </ul>
            <p><b>What happens next?</b></p>
  <Accordion>
  <Card>
    <Card.Header>
      <Accordion.Toggle as={Button} variant="link" eventKey="0">
        How to use
      </Accordion.Toggle>
    </Card.Header>
    <Accordion.Collapse eventKey="0">
      <Card.Body>
        <Card.Title>
          Generating Your Stock Order
        </Card.Title>
        <div className="text-muted">
          <p>Navigate to your software vendors stock history function.</p>
          <p>Select the time period you want to base your order on.</p>
          <p><b>Important: </b>Select the parameters: <b>Product Name</b>, <b>Dispensed Quantity</b> and <b>GMS Code</b>.</p>
          <p>Below is a screenshot from the McLernon's dispensing software.</p>
          <Card.Img src={stockhistory1} variant="top" />
          <p> </p>
          <p>Generate the report.</p> 
          <p><b>Important: </b>Export to a <b>csv</b> file.</p>
          <Card.Img src={stockhist2} variant="top" />
          <p> </p>
          <p>Upload file to Swiftask.</p>
          <div className='text-center mb-3'>
            <img src={stockUpload}
            alt="gif"
            height="300"
            width="500" />
          </div>

          <p>Receive your PCO and Generic orders.</p>
          <div className='text-center mt-2'>
            <img src={openStockOrder}
            alt="gif"
            height="300"
            width="500" />
            </div>
        </div>
      </Card.Body>
    </Accordion.Collapse>
  </Card>
  </Accordion>
        <p className='mt-3'><b>How do I get started?</b></p>
        <Link to="/orders">Click here</Link>
        {/* add a gif from assets in a thumbnail size */}
          </div>
        </div>
        </Styles>
    );
};