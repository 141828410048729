import { Form, Col, Row, Card, Accordion, Button, CardDeck } from 'react-bootstrap'
import React, { useRef, useState} from "react";
import LoaderButton from "../LoaderButton";
import { Storage } from 'aws-amplify';
import { onError } from '../../libs/errorLib';
import styled, { keyframes } from 'styled-components'
import { bounceIn } from 'react-animations';
import { faCloudDownloadAlt, faFileDownload } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { sendUserDB } from '../../libs/awsLib';
import VatDateRangePicker from './vatSelector';
import { useAppContext } from '../../libs/contextLib';

const swingAnimation = keyframes`${bounceIn}`;

const Styles = styled.div`
  .fa-cloud-download-alt {
    margin-right: .5rem;
  }
  .file-border {
    border: 1px solid #dee2e6;
    width: max-content;
    border-radius: 5px;
  }
  .head {
    font-weight: 550;
    border-bottom: 1px solid #dee2e6; 
  }
  .s3link {
    font-size: 15px;
    animation: 1s ${swingAnimation};
    color: #007bff;
  }
  .fa-file-download {
    margin-right: .5rem;
  }
`;

export const QueryForm = () => {
    const [isLoading, setIsLoading] = useState(false);
    const [signedURL, setSignedUrl] = useState('');
    const { reasonsForError } = useAppContext();

  async function getPCRSQueryForm () {

    setIsLoading(true);

    try {
    const queryFormFile = await getQueryForm();
    console.log('queryform file', queryFormFile);
    const theURL = await Storage.get(queryFormFile, {level: 'private', contentType: 'string', download: "true"});
    console.log("TheURL: ", theURL);
    setSignedUrl(theURL);

    setIsLoading(false);
    } catch (e) {
      onError(e);
      setIsLoading(false);
    }}
    
  const getQueryForm = async () => {
    const reasonJSON = JSON.stringify(reasonsForError);

    const queryform = await sendUserDB(reasonJSON);
    return queryform;
  }
    return (
      <Styles>
    <Row className=" pl-1 pt-3 pb-4 justify-content-md-center">
      <Col className='pb-2'>
      <Card className="text-center h-100">
        <Card.Body>
          <Card.Title>PCRS Query Form</Card.Title>
          <hr></hr>
        <Card.Text className="text-muted " style={{fontSize: 'small'}}>
          Generates a query form for the PCRS.
          <br></br>
          <br></br>
          All rejects from the table above will be included in the query form.
          </Card.Text>

        <LoaderButton
          className="mb-3 justify-content-center"
          type="submit"
          size="sm"
          variant="info"
          isLoading={isLoading}
          onClick={getPCRSQueryForm}
        >
          <FontAwesomeIcon icon={faCloudDownloadAlt} size="1x"/>
          Download PCRS Query Form
        </LoaderButton>
        { signedURL ? 
              <a className="file-border s3link pt-1 pb-1 pl-1 pr-1" href={signedURL} target="_blank">
          <FontAwesomeIcon icon={faFileDownload} size="1x"/>
          PCRS Query Form
              </a>
          : null }
          </Card.Body>
      </Card>
          </Col>
        <Col>
          <VatDateRangePicker />
          </Col>

    </Row>
    </Styles>
    )
        }