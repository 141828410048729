import styled from 'styled-components'
import { Link } from 'react-router-dom'
import { Row, Card, Col, Button } from 'react-bootstrap'
import teamwork from './assets/teamwork.jpg'
import Helmet from 'react-helmet';

const Styles = styled.div`
    .rowFlex {
        display: flex;
        flex-wrap: wrap;    
    }
    .heading {
        margin: 40px 0px 20px;
        text-align: center;
        font-family: "Open Sans", sans-serif;
        font-size: 42px;
        font-weight: 700;
        letter-spacing: -1px;
        line-height: 32px;
    }
    .card {
    box-shadow: 0 0 10px 0 rgba(100, 100, 100, 0.26);
    }
    .bottom-col {
        margin-bottom: 30px;
    }
    .btn-props {
        box-shadow: 0 8px 16px 0 rgba(0,0,0,0.2), 0 6px 20px 0 rgba(0,0,0,0.19);
        margin-bottom: 15px;
    }
    h3 {
        margin-bottom: 30px;
        font-weight: 600;
        letter-spacing: -0.048em;
    }
    @media screen and (max-width: 768px) {
     .rowFlex {
       flex-direction: column;
       font-size: 16px;
       text-align: initial;
      }
      h3 {
            font-size: 24px;
            font-weight: 600;
        }
    }
`
;
const AboutPage = () => {
    return (
        <Styles>
            <Helmet>
                <title>About</title>
            </Helmet>
            <h2 className="heading">About Swiftask</h2>
            <h6 className="text-center text-muted">Pharmacists spend too much time doing paperwork.</h6>
                <h6 className="text-center text-muted">Swiftask was created to make your life easier.</h6>
            <Row className="rowFlex mt-5 mb-5">
                <Col>
            <p>I'm a Supervising Pharmacist with over 10 years experience in community Pharmacy. FEMPI cutbacks and the Pharmacy Act of 2007 have changed pharmacy
                drastically. Many pharmacies are operating with a skeleton staff.
                As a result the only time to get on top of paperwork is outside work hours.
            </p>
            <p>I want to help.</p>
            <p>I've designed this website to ease your workload. You are a highly trained medical professional and shouldn't be up to your eyeballs
                in paperwork.
            </p>
            <p>Ciaran</p>
                </Col>
                <Col>
                <Card>
            <Card.Img className="img-fluid" variant="top" src={teamwork} />
                </Card>
                </Col>
            </Row>
            
        <Col className="bottom-col col-12 text-center">
            <h3>Start freeing up time straight away</h3>
            <Button as={Link} to="/register" className="btn-props text-center" variant="danger">Try out one month for free</Button> 
            </Col>
        </Styles>
    );
};

export default AboutPage;