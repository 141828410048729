import React from 'react'
import { InfoCards } from './components/InfoCards'
import { Jumbotron } from './components/Jumbotron'
import { ExtraTime } from './components/ExtraTime'
import { DataFlowStock } from './components/DataFlowStock'
import { Helmet } from 'react-helmet';

const Home = () => {
    return (
        <div className=''>
            <Helmet>
                <title>Home</title>
            </Helmet>
            <Jumbotron />
            <ExtraTime />
            <div className='container'>
            <InfoCards />
            <DataFlowStock />
            </div>
        </div>
    );
};

export default Home;