import styled from 'styled-components'
import { Button } from 'react-bootstrap' 
import { Link } from 'react-router-dom'
import { Helmet } from 'react-helmet'

const Styles = styled.div`
    margin: 20px;
    .display-text {
        font-weight: 700;
    }
`
;

export const Pricing = () => {
    return (
        <Styles>
            <Helmet>
                <title>Pricing</title>
            </Helmet>
        <div>
        <div class="pricing-header px-3 py-3 pb-md-4 mx-auto text-center">
        <h1 className="display-text">Pricing</h1>
        <p class="lead text-muted">Sign up in minutes and start freeing up time in your workday.</p>
        </div>

        <div class="container">
        <div class="card-deck mb-3 text-center">
            <div class="card mb-4 box-shadow">
            <div class="card-header">
                <h4 class="my-0 font-weight-normal">Free</h4>
            </div>
            <div class="card-body">
                <h1 class="card-title pricing-card-title">€0</h1>
                <ul class="list-unstyled mt-3 mb-4 text-muted">
                <li>One month free trial period</li>
                <li>Access to all features</li>
                <li>Email support</li>
                </ul>
                <Button as={Link} to="/register" className="btn-lg btn-block text-center" variant="outline-primary">Sign Up for free</Button> 
            </div>
            </div>
            <div class="card mb-4 box-shadow">
            <div class="card-header">
                <h4 class="my-0 font-weight-normal">Pro</h4>
            </div>
            <div class="card-body">
                <h1 class="card-title pricing-card-title">€29.99 <small class="text-muted">/ mo</small></h1>
                <ul class="list-unstyled mt-3 mb-4 text-muted">
                <li>Access to all features</li>
                <li>Priority email support</li>
                <li>Help center access</li>
                </ul>
                <Button as={Link} to="/register" className="text-center btn-block btn-lg" variant="primary">Get Started</Button> 
            </div>
            </div>
            <div class="card mb-4 box-shadow">
            <div class="card-header">
                <h4 class="my-0 font-weight-normal">Enterprise</h4>
            </div>
            <div class="card-body">
                <h1 class="card-title pricing-card-title">€299 <small class="text-muted">/ yr</small></h1>
                <ul class="list-unstyled mt-3 mb-4 text-muted">
                <li>Access to all features</li>
                <li>Phone and email support</li>
                <li>Help center access</li>
                </ul>
                <Button as={Link} to="/register" className="text-center btn-block btn-lg" variant="primary">Contact Us</Button> 
            </div>
            </div>
            </div>
        </div>
        </div>
        </Styles>
    );
};