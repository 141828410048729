import { Card, Row, Col } from 'react-bootstrap'
import { Link } from 'react-router-dom'
export const NoStockData = () => {
	return (
		<Row className="pt-2 pb-2">
		<Col className='d-flex justify-content-center'>
	      <Card className='text-center' style={{width: "265px", height: "152px"}}>
		      <Card.Body>
			<Card.Title style={{fontSize: "large"}} >Stock Data</Card.Title>
			  <hr></hr>
			    <Card.Text style={{fontSize: "12px"}} className='mb-2'>
				    Upload <Link to='/orders'>Stock Order</Link> to analyse Generic compliance
			    </Card.Text>
		      </Card.Body>
		    </Card>
		</Col>
	      </Row>
	)
}