import React, { useState } from "react";
import { Form } from 'react-bootstrap'
import { Link, useHistory } from 'react-router-dom'
import { onError } from "./libs/errorLib";
import styled from 'styled-components'
import LoaderButton from './components/LoaderButton'
import { Auth } from 'aws-amplify';
import { useAppContext } from "./libs/contextLib";
import { useFormFields } from "./libs/hooksLib"
import { Helmet } from 'react-helmet'

const Styles = styled.div`
    .heading {
        margin: 40px 0px 20px;
        text-align: center;
        font-family: "Open Sans", sans-serif;
        font-size: 42px;
        font-weight: 700;
        letter-spacing: -1px;
        line-height: 32px;
    }
    @media all and (min-width: 480px) {
        .Login {
            padding: 60px 0;
        }

        .Login form {
            margin: 0 auto;
            max-width: 320px;
        }
}
    
`
;
    
export default function LoginPage() {
    const [isLoading, setIsLoading] = useState(false);
    const { userHasAuthenticated } = useAppContext();
    const history = useHistory();    
    const [fields, handleFieldChange] = useFormFields({
        email: "",
        password: "",
    });

    function validateForm() {
      return fields.email.length > 0 && fields.password.length > 0;
    }
  
    async function handleSubmit(event) {
        event.preventDefault();
        setIsLoading(true);

        try {
                await Auth.signIn(fields.email, fields.password);
                userHasAuthenticated(true);
                history.push("/dashboard");
            } catch (e) {
                onError(e);
                setIsLoading(false);
    }
        }

  
    return (
        <Styles>
          <Helmet>
                <title>Login</title>
            </Helmet>
      <div className="Login">
        <Form onSubmit={handleSubmit}>
            <h2 className="heading">Sign In</h2>
          <Form.Group size="lg" controlId="email">
            <Form.Label>Email</Form.Label>
            <Form.Control
            //   autoFocus
              type="email"
              value={fields.email}
              onChange={handleFieldChange}
            />
          </Form.Group>
          <Form.Group size="lg" controlId="password">
            <Form.Label>Password</Form.Label>
            <Form.Control
              type="password"
              value={fields.password}
              onChange={handleFieldChange}
            />
          </Form.Group>
          <Link to="/reset">Forgot password?</Link>

          <LoaderButton 
          block size="lg" 
          type="submit" 
          disabled={!validateForm()}
          isLoading={isLoading}>
            Login
          </LoaderButton>
        </Form>
      </div>
      </Styles>
    );
  }