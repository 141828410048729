import { Form, Col, Button, Alert, Row } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import LoaderButton from "./components/LoaderButton"
import styled from 'styled-components'
import { useFormFields } from './libs/hooksLib'
import { useState } from 'react'
import { sendEmail }  from "./libs/awsLib"
import { Helmet } from 'react-helmet'

const Styles = styled.div`
    .heading {
        margin: 40px 0px 20px;
        text-align: center;
        font-family: "Open Sans", sans-serif;
        font-size: 42px;
        font-weight: 700;
        letter-spacing: -1px;
        line-height: 32px;
    }
`
;


const Contact = () => {
  const [fields, handleFieldChange] = useFormFields({
    name: "",
    email: "",
    message: ""
  });
  const [sent, setSent] = useState(false);

  const handleSubmitMessage = async (event) => {
    event.preventDefault();
    const stringifiedBody = JSON.stringify(fields);
    const response = await sendEmail(stringifiedBody);
    console.log(response);
    console.log('submitted');
    setSent(!sent);
    console.log(event);
  }


    return (
      <div>
        <Helmet>
          <title>Contact</title>
        </Helmet>
      { !sent ?
        <Styles>
            <h2 className="heading">Contact</h2>
            <h6 className="text-muted text-center mb-3">Email info@swiftask.ie or alternatively, fill in the form below.</h6>
            <Form onSubmit={handleSubmitMessage}>
              <Form.Group as={Col} md={5} className="mx-auto" controlId="name">
                <Form.Label>Name</Form.Label>
                  <Form.Control 
                    required 
                    type="name" 
                    placeholder="Your Name"
                    value={fields.name}
                    onChange={handleFieldChange} />
              </Form.Group>
              <Form.Group as={Col} md={5} className="mx-auto" controlId="email">
                <Form.Label>Email address</Form.Label>
                  <Form.Control 
                    required 
                    type="email" 
                    placeholder="name@example.com"
                    value={fields.email}
                    onChange={handleFieldChange} />
              </Form.Group>   
              <Form.Group as={Col} md={5} className="mx-auto" controlId="message">
                <Form.Label>Message</Form.Label>
                  <Form.Control 
                    required 
                    placeholder='Your message here. ' 
                    as="textarea"
                    value={fields.message}
                    onChange={handleFieldChange} 
                    rows={3}/>
              </Form.Group>
              <Col md={5} className="text-center mx-auto mb-5">
                <LoaderButton className='text-center'
                type="submit"
                bsSize="large"
                >
                  Send
                </LoaderButton>
              </Col>
            </Form>
        </Styles>
        :
        <Row className='pt-3'>
           <Col md={{ span: 4, offset: 4 }}>
        <Alert className='mt-3 text-center' variant='success'>Your message has been sent.</Alert>
          </Col>
        </Row>
      }
      </div>
    );
};

export default Contact; 