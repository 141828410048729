import { Route, Redirect } from 'react-router-dom'
import { useAppContext } from "./libs/contextLib";


export const PrivateRoute = ({ component: Component, ...rest }) => {
    const { isAuthenticated } = useAppContext();
    return (
    <Route
      {...rest}
      render={props =>
        isAuthenticated ? (
          <Component {...props} />
        ) : (
          <Redirect
            to={{
              pathname: "/login"
            }}
          />
        )
      }
    />
  );
    };