/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "eu-west-1",
    "aws_cloud_logic_custom": [
        {
            "name": "claimsapi",
            "endpoint": "https://o3r83jjls4.execute-api.eu-west-1.amazonaws.com/prod",
            "region": "eu-west-1"
        },
        {
            "name": "paidclaimsapi",
            "endpoint": "https://nc0lx21x3i.execute-api.eu-west-1.amazonaws.com/prod",
            "region": "eu-west-1"
        },
        {
            "name": "pollingtableapi",
            "endpoint": "https://ehlore9ryf.execute-api.eu-west-1.amazonaws.com/prod",
            "region": "eu-west-1"
        },
        {
            "name": "queryformapi",
            "endpoint": "https://vq63kj1bmg.execute-api.eu-west-1.amazonaws.com/prod",
            "region": "eu-west-1"
        },
        {
            "name": "removeclaimsapi",
            "endpoint": "https://bfs5k486kh.execute-api.eu-west-1.amazonaws.com/prod",
            "region": "eu-west-1"
        },
        {
            "name": "sendemailapi",
            "endpoint": "https://5hbnk8ao0e.execute-api.eu-west-1.amazonaws.com/prod",
            "region": "eu-west-1"
        },
        {
            "name": "sendgridapi",
            "endpoint": "https://0p6mjlp5kb.execute-api.eu-west-1.amazonaws.com/prod",
            "region": "eu-west-1"
        },
        {
            "name": "stockapi",
            "endpoint": "https://o13oochlqj.execute-api.eu-west-1.amazonaws.com/prod",
            "region": "eu-west-1"
        },
        {
            "name": "stockpercentagesapi",
            "endpoint": "https://c6ezru6id7.execute-api.eu-west-1.amazonaws.com/prod",
            "region": "eu-west-1"
        },
        {
            "name": "vatfilesapi",
            "endpoint": "https://ok9d2r8mc4.execute-api.eu-west-1.amazonaws.com/prod",
            "region": "eu-west-1"
        }
    ],
    "aws_cognito_identity_pool_id": "eu-west-1:ded4ec4c-494b-41ff-a8d3-76afba638b14",
    "aws_cognito_region": "eu-west-1",
    "aws_user_pools_id": "eu-west-1_VrdZ6WRb5",
    "aws_user_pools_web_client_id": "575rh8hsq0ijt72nqkiucbp0e5",
    "oauth": {},
    "aws_cognito_username_attributes": [
        "EMAIL"
    ],
    "aws_cognito_social_providers": [],
    "aws_cognito_signup_attributes": [
        "EMAIL"
    ],
    "aws_cognito_mfa_configuration": "OFF",
    "aws_cognito_mfa_types": [
        "SMS"
    ],
    "aws_cognito_password_protection_settings": {
        "passwordPolicyMinLength": 8,
        "passwordPolicyCharacters": []
    },
    "aws_cognito_verification_mechanisms": [
        "EMAIL"
    ],
    "aws_user_files_s3_bucket": "appstorage203054-prod",
    "aws_user_files_s3_bucket_region": "eu-west-1",
    "aws_dynamodb_all_tables_region": "eu-west-1",
    "aws_dynamodb_table_schemas": [
        {
            "tableName": "pollingTable-prod",
            "region": "eu-west-1"
        }
    ]
};


export default awsmobile;
