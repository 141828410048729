import { Card, Button, Col } from "react-bootstrap"
import React from 'react'
import styled from 'styled-components'
import busyBob from '../assets/busyBob.jpg'
import bobHappy from '../assets/bobHappy.jpg'

const Styles = styled.div`
    .card-img-top {
        width: 70%;
    }
    .card-title {
      margin-bottom: 20px;
    }
    .card {
      margin-top: 30px;
    }
    @media screen and (max-width: 768px) {
        .card-img-top {
            width: 100%;
        }
        ,cardFont {
          font-size: 13px;
        }
    }
`
;

const lifeBefore = "Life before Swiftask";
const lifeAfter = "Life with Swiftask";

const beforeSwiftask = ["Bob is a supervising pharmacist who finds it difficult to find time to complete all his administrative tasks.", 
                "Aside from the day to day responsibilities of his job, he also has to:", "- prepare his Pharmacy to comply with COVID operational standards",
                "- make sure the PCRS are paying him for all the medicines", "- go through his previous months stock report and place an order with PCO and the generic\
                company", "- and now he has to set up his Pharmacy for vaccinations", "Bob doesn't find time to get around to all of this during his work day\
                because he is so busy, therefore he ends up doing it after work and on weekends",
                "This is a typical situation for many Irish pharmacists, but it doesn't have to be like this..."]

const afterSwiftask = ["Bob discovers Swiftask and uploads a couple of months of his claim files and stock reports for processing.", "He is gobsmacked when Swiftask:", "- displays all his paid reclaims and outstanding rejects", "- puts all his unpaid rejects\
                onto a PCRS query form", "- finds his hardship payments", "- identifies all the common claim errors he is making", "- prepares his stock orders for next month instantly", "- shows him a snapshot of all ingredients dispensed and the generic percentages", "He can't believe he doesn't have to painfully flick through\
                hundreds of pages of claim summaries to find what he is looking for. Swiftask has freed up so much time for Bob."]

export default class UpgradedButton extends React.Component{
    constructor(props){
      super(props)
      this.state = {
        buttonText: "With Swiftask",
        divText: beforeSwiftask,
        imgBob: busyBob,
        cardTitle: lifeBefore, 
      }
    }

    handleClick = () => {
      let buttonText = this.state.buttonText === "With Swiftask" ? "Before Swiftask" : "With Swiftask"
      let divText = this.state.divText === beforeSwiftask ? afterSwiftask :beforeSwiftask 
      let imgBob = this.state.imgBob === busyBob ? bobHappy : busyBob
      let cardTitle = this.state.cardTitle === lifeBefore ? lifeAfter : lifeBefore
      this.setState({buttonText: buttonText})
      this.setState({divText: divText})
      this.setState({imgBob: imgBob})
      this.setState({cardTitle: cardTitle})
    }

    render(){
      return (
          <Styles>
              <Card>
              <Card.Img variant="top" className="mx-auto" src={this.state.imgBob} />
                <Card.Body className="cardFont">
          <Card.Title className="text-center">{this.state.cardTitle}</Card.Title>
          <Card.Text className="text-muted">
            {this.state.divText.map(item => {
                return (
                    <p>{item}</p>
                )
            })}
          </Card.Text>
          <Col className="text-center">
         <Button onClick={this.handleClick} variant="primary">{this.state.buttonText}</Button>
         </Col>
        </Card.Body>
        
            </Card>
       </Styles>
      ) 
    }
};
