
import { Card, ListGroup } from 'react-bootstrap'

export const CommonErrors = (props) => (
<Card className='text-center pb-4 h-100'>
          <Card.Body>
            <Card.Title style={{fontSize: "large"}} >Most Common Errors</Card.Title>
              <hr></hr>
                <Card.Text style={{fontSize: 'small'}}>
                  <ListGroup variant="flush">
                    { props.errorHTML() }
                  </ListGroup>
                </Card.Text>
          </Card.Body>
        </Card>

)