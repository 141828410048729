import React, { useState, useEffect} from 'react';
import { BrowserRouter as Router, Route, Switch, Redirect} from "react-router-dom";
import Home from './Home'
import Contact from './Contact'
import About from './About'
import Faq from './Faq'
import Register from './Register'
import Login from './Login'
import NoMatch from './NoMatch.js'
import { Layout } from './components/Layout';
import NavigationBar from './components/Navigation';
import { Footer } from './components/Footer'
import './App.css'
import { Pricing } from './Pricing';
import { Dashboard } from './Dashbboard';
import { Auth } from 'aws-amplify';
import Amplify from 'aws-amplify'
import awsconfig from './aws-exports';
import { AppContext } from "./libs/contextLib";
import { onError } from "./libs/errorLib";
import ResetPassword from './ResetPassword';
import Settings from './Settings'
import ChangePassword from './ChangePassword';
import { PrivateRoute } from './PrivateRoute'
import  Orders from './Orders';
import Claims from './Claims';
import Privacy from './Privacy'
import TermsOfUse from './TermsOfUse';
import { Guide } from './Guide';

// Amplify.configure(awsconfig);

const AuthStateApp = () => {
  // when we first load app we set it to true as it will begin by checking current auth state
  const [isAuthenticating, setIsAuthenticating] = useState(true);
  const [isAuthenticated, userHasAuthenticated] = useState(false);

  useEffect(() => {
    onLoad();
    }, []);

    // only runs on first render
  async function onLoad() {
    try {
      await Auth.currentSession();
      // this returns a promise so we need to ensure app is ready to go only after this has been loaded
      userHasAuthenticated(true);
      // const credentials = await Auth.currentUserCredentials();
    }
    catch(e) {
      if (e !== 'No current user') {
        console.log(e);
      }
    }

    setIsAuthenticating(false);
  }

  return (
    !isAuthenticating && (
      <React.Fragment>
        <AppContext.Provider value={{ isAuthenticated, userHasAuthenticated }}>
        <Router>
          <NavigationBar />
                  <Layout>
                          { isAuthenticated ?
                          <Switch>
                        <PrivateRoute exact path="/" component={Dashboard} />
                        <PrivateRoute path="/settings" component={Settings} />
                        <PrivateRoute path="/password" component={ChangePassword} />
                        <PrivateRoute path="/orders" component={Orders} />
                        <PrivateRoute path="/dashboard" component={Dashboard} />
                        <PrivateRoute path="/claims" component={Claims} />
                        <PrivateRoute path="/guide" component={Guide} />
                        <Route path="/terms" component={TermsOfUse} />
                        <Route path="/privacy" component={Privacy} />
                        <Redirect from="/*" to="/" />
                        </Switch>
                        :
                         <Switch>
                         <Route exact path="/" component={Home} />
                        <Route exact path="/about" component={About} />
                        <Route path="/reset" component={ResetPassword} />
                        <Route path="/faq" component={Faq} />
                        <Route path="/guide" component={Guide} />
                        <Route path="/contact" component={Contact} />
                        <Route path="/register" component={Register} />
                        <Route path="/login" component={Login} />
                        <Route path="/pricing" component={Pricing} />
                        <Route path="/terms" component={TermsOfUse} />
                        <Route path="/privacy" component={Privacy} />
                        <PrivateRoute path="/settings" component={Settings} />
                        <PrivateRoute path="/password" component={ChangePassword} />
                        <PrivateRoute path="/orders" component={Orders} />
                        <PrivateRoute path="/dashboard" component={Dashboard} />
                        <PrivateRoute path="/claims" component={Claims} />
                        <Redirect from="/*" to="/" />
                        </Switch>}
                  </Layout>
                  <Footer />
        </Router>
        </AppContext.Provider>
      </React.Fragment>
  )
  )
};
export default AuthStateApp;
